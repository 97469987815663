      <!-- 专科体查表单 -->
      <template>
        <div class="physical">
          <el-dialog title="专科体查" :visible.sync="dialogVisible" :width="dialogwid">
            <el-progress :percentage="percentage" ></el-progress>
            <div v-show="next == 2">
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">视</p>
              <div class="grid-content-left">
                <div class="from-topic">步态观察：</div>
                <el-checkbox-group
                v-model="value[0].gait" 
                @change="changecheckbox">
                  <el-checkbox
                    v-for="(item, index) in shi[0].value"
                    :label="item"
                    :key="index"
                    style="margin: 0 5px 10px 0; padding:7px;"
                    :border='true' 
                    size="medium" 
                  >{{ item }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <!-- 其他 -->
              <div class="grid-content-left" v-show="other">
                <div class="from-topic">其他为：</div>
                <el-input v-model="value[0].other"></el-input>
              </div>
            </div>
            <div v-show="next == 3">
              <!-- 畸形情况 -->
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">畸形情况</p>
              <div class="grid-content-left">
                <div class="from-topic">左侧：</div>
                <el-radio-group v-model="value[0].lt" :min="0" :max="1">
                  <el-radio
                    v-for="(item, index) in shi[1].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-radio
                  >
                </el-radio-group>
                <div class="from-topic">右侧：</div>
                <el-radio-group v-model="value[0].rt" :min="0" :max="1">
                  <el-radio
                    v-for="(item, index) in shi[1].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-radio
                  >
                </el-radio-group>
              </div>
              <!-- 触 -->
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">触</p>
              <div class="grid-content-left">
                <div class="from-topic">皮温：</div>
                <el-radio-group v-model="value[0].mst" :min="0" :max="1">
                  <el-radio
                  v-for="(item, index) in chu[0].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
            <div v-show="next == 4">
              <!-- 压痛 -->
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">压痛</p>
              <div class="grid-content-left">
                <div class="from-topic">左侧：</div>
                <el-checkbox-group v-model="value[1].lt">
                  <el-checkbox
                    v-for="(item, index) in chu[1].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-checkbox
                  >
                </el-checkbox-group>
                <div class="from-topic">右侧：</div>
                <el-checkbox-group v-model="value[1].rt">
                  <el-checkbox
                    v-for="(item, index) in chu[1].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <!-- 动 -->
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">动</p>
              <div class="grid-content-left">
                <el-checkbox-group v-model="value[2].inspect">
                  <el-checkbox
                    v-for="(item, index) in dong[0].value"
                    :label="item"
                    :key="index"
                    border
                    >{{ item }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div v-show="next == 5">
              <!-- 肌力 -->
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">肌力</p>
              <div class="grid-content-left">
                <div class="from-topic">左下肢：</div>
            <grade :sel="value[2].lt" @itemselected="getgrade1" :alt="false"></grade>

                <!-- <el-input   type="range"  min="0" max="5" :value="value[2].lt" v-model="value[2].lt"></el-input>
                <div class="jibie">{{ value[2].lt }}级</div> -->

                <div class="from-topic">右下肢：</div>
            <grade :sel="value[2].rt" @itemselected="getgrade2" :alt="false"></grade>

                <!-- <el-input   type="range"  min="0" max="5" :value="value[2].rt" v-model="value[2].rt"></el-input>
                <div class="jibie">{{ value[2].rt }}级</div> -->
              </div>
            </div>
            <div v-show="next == 6">
              <!-- 量 -->       
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">测量 - 左侧</p>
              <div class="grid-content-left">
                <div class="from-topic">周径：</div>
                <el-input v-model="value[3].lt[0].diameter"><template slot="append">CM</template></el-input>
                <div class="from-topic">大腿围：</div>
                <el-input v-model="value[3].lt[0].thigh"><template slot="append">CM</template></el-input>
                <div class="from-topic">小腿围：</div>
                <el-input v-model="value[3].lt[0].calf"><template slot="append">CM</template></el-input>
              </div>
              <div class="grid-content-left">
                <div class="from-topic">ROM：</div>
                <el-input
                  v-model="value[3].lt[0].rom1"
                ><template slot="append">°</template></el-input>
                <el-input
                  v-model="value[3].lt[0].rom2"
                ><template slot="append">°</template></el-input>
                <el-input
                  v-model="value[3].lt[0].rom3"
                ><template slot="append">°</template></el-input>
              </div>
            </div>  
            <div v-show="next == 7">
              <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">测量 - 右侧</p>
              <div class="grid-content-left">
                <div class="from-topic">周径：</div>
                <el-input v-model="value[3].rt[0].diameter"><template slot="append">CM</template></el-input>
                <div class="from-topic">大腿围：</div>
                <el-input v-model="value[3].rt[0].thigh"><template slot="append">CM</template></el-input>
                <div class="from-topic">小腿围：</div>
                <el-input v-model="value[3].rt[0].calf"><template slot="append">CM</template></el-input>
              </div>
              <div class="grid-content-left">
                <div class="from-topic">ROM：</div>
                <el-input
                  v-model="value[3].rt[0].rom1"
                ><template slot="append">°</template></el-input>
                <el-input
                  v-model="value[3].rt[0].rom2"
                ><template slot="append">°</template></el-input>
                <el-input
                  v-model="value[3].rt[0].rom3"
                ><template slot="append">°</template></el-input>
              </div>
            </div>
            
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="theprevious" type="warning">上一步</el-button>
              <el-button @click="nextlist" :disabled="next == 7" type="primary">下一步</el-button>
              <el-button type="danger" @click="showexamination">确 定</el-button>
            </span>
          </el-dialog>
      <el-form  v-show="showform" class="mainbody">
        <div style="width: 100%; overflow: auto;">
          <!-- 视 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">视</p>
          <div class="grid-content-left">
            <div class="from-topic">步态观察：</div>
            <el-checkbox-group
            v-model="value[0].gait" 
            @change="changecheckbox">
              <el-checkbox
                v-for="(item, index) in shi[0].value"
                :label="item"
                :key="index"
                :disabled="modify"
                style="margin: 0 5px 10px 0; padding:7px;"
                border 
                size="medium" 
              >{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 其他 -->
          <div class="grid-content-left" v-show="other">
            <div class="from-topic">其他为：</div>
            <el-input v-model="value[0].other" :disabled="modify"></el-input>
          </div>
          <!-- 畸形情况 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">畸形情况</p>
          <div class="grid-content-left">
            <div class="from-topic">左侧：</div>
            <el-radio-group v-model="value[0].lt" :min="0" :max="1">
              <el-radio
                v-for="(item, index) in shi[1].value"
                :label="item"
                :key="index"
                :disabled="modify"
                border
                >{{ item }}</el-radio
              >
            </el-radio-group>
            <div class="from-topic">右侧：</div>
            <el-radio-group v-model="value[0].rt" :min="0" :max="1">
              <el-radio
                v-for="(item, index) in shi[1].value"
                :label="item"
                :key="index"
                :disabled="modify"
                border
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div>
          <!-- 触 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">触</p>
          <div class="grid-content-left">
            <div class="from-topic">皮温：</div>
            <el-radio-group v-model="value[0].mst" :min="0" :max="1">
              <el-radio
               v-for="(item, index) in chu[0].value"
                :label="item"
                :key="index"
                :disabled="modify"
                border
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div>
          <!-- 压痛 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">压痛</p>
          <div class="grid-content-left">
            <div class="from-topic">左侧：</div>
            <el-checkbox-group v-model="value[1].lt">
              <el-checkbox
                v-for="(item, index) in chu[1].value"
                :label="item"
                :key="index"
                :disabled="modify"
                border
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <div class="from-topic">右侧：</div>
            <el-checkbox-group v-model="value[1].rt">
              <el-checkbox
                v-for="(item, index) in chu[1].value"
                :label="item"
                :key="index"
                :disabled="modify"
                border
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- 动 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">动</p>
          <div class="grid-content-left">
            <el-checkbox-group v-model="value[2].inspect">
              <el-checkbox
                v-for="(item, index) in dong[0].value"
                :label="item"
                :key="index"
                :disabled="modify"
                :border="true"
                >{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 肌力 -->
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">肌力</p>
          <div class="grid-content-left">
            <div class="from-topic">左下肢：</div>
            <grade :sel="value[2].lt" @itemselected="getgrade1" :alt="modify"></grade>
            <!-- <el-input   type="range"  min="0" max="5" :value="value[2].lt" v-model="value[2].lt" :disabled="modify"></el-input> -->
            <!-- <div class="jibie">{{ value[2].lt }}级</div> -->

            <div class="from-topic">右下肢：</div>
            <grade :sel="value[2].rt" @itemselected="getgrade2" :alt="modify"></grade>
            <!-- <el-input   type="range"  min="0" max="5" :value="value[2].rt" v-model="value[2].rt" :disabled="modify"></el-input> -->
            <!-- <div class="jibie">{{ value[2].rt }}级</div> -->
          </div>
          <!-- 量 -->       
          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">测量 - 左侧</p>
          <div class="grid-content-left">
            <div class="from-topic">周径：</div>
            <el-input v-model="value[3].lt[0].diameter" :disabled="modify"><template slot="append">CM</template></el-input>
            <div class="from-topic">大腿围：</div>
            <el-input v-model="value[3].lt[0].thigh" :disabled="modify"><template slot="append">CM</template></el-input>
            <div class="from-topic">小腿围：</div>
            <el-input v-model="value[3].lt[0].calf" :disabled="modify"><template slot="append">CM</template></el-input>
          </div>
          <div class="grid-content-left">
            <div class="from-topic">ROM：</div>
            <el-input
              v-model="value[3].lt[0].rom1"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
            <el-input
              v-model="value[3].lt[0].rom2"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
            <el-input
              v-model="value[3].lt[0].rom3"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
          </div>

          <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0;">测量 - 右侧</p>
          <div class="grid-content-left">
            <div class="from-topic">周径：</div>
            <el-input v-model="value[3].rt[0].diameter" :disabled="modify"><template slot="append">CM</template></el-input>
            <div class="from-topic">大腿围：</div>
            <el-input v-model="value[3].rt[0].thigh" :disabled="modify"><template slot="append">CM</template></el-input>
            <div class="from-topic">小腿围：</div>
            <el-input v-model="value[3].rt[0].calf" :disabled="modify"><template slot="append">CM</template></el-input>
          </div>
          <div class="grid-content-left">
            <div class="from-topic">ROM：</div>
            <el-input
              v-model="value[3].rt[0].rom1"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
            <el-input
              v-model="value[3].rt[0].rom2"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
            <el-input
              v-model="value[3].rt[0].rom3"
              :disabled="modify"
            ><template slot="append">°</template></el-input>
          </div>



        </div>
      </el-form>



        <!-- 旧的 -->
          <div class="mainbody" v-show="false" >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">视</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">步态观察</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="21" :xl="21">
                <div class="grid-content" style="text-align: left;">
                  <el-checkbox-group v-model="value[0].gait" @change="changecheckbox">
                    <el-checkbox
                      v-for="(item, index) in shi[0].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3" v-show="other">
                <div class="grid-content bg-purple-light">其他</div>
              </el-col>
              <el-col :xs="18" :sm="18" :md="18" :lg="21" :xl="21" v-show="other"
                >
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="value[0].other"
                    :disabled="modify"
                  >
                </el-input>
                </div>
                </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">畸形情况</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">左侧</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="9" :xl="9"
                ><div class="grid-content">
                  <el-radio-group v-model="value[0].lt" :min="0" :max="1">
                    <el-radio
                      v-for="(item, index) in shi[1].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      
                      >{{ item }}</el-radio
                    >
                  </el-radio-group>
                </div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">右侧</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="9" :xl="9"
                ><div class="grid-content">
                  <el-radio-group v-model="value[0].rt" :min="0" :max="1">
                    <el-radio
                      v-for="(item, index) in shi[1].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-radio
                    >
                  </el-radio-group>
                </div></el-col
              >
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">触</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">皮温</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="21" :xl="21"
                ><div class="grid-content">
                  <el-radio-group v-model="value[1].mst" :min="0" :max="1">
                    <el-radio
                      v-for="(item, index) in chu[0].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-radio
                    >
                  </el-radio-group>
                </div></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">压痛</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="2" :xl="2"
                ><div class="grid-content bg-purple-light">左侧</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="10" :xl="10"
                ><div class="grid-content" style="text-align: left;">
                  <el-checkbox-group v-model="value[1].lt">
                    <el-checkbox
                      v-for="(item, index) in chu[1].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div></el-col
              >
              <el-col :xs="6" :sm="6" :md="6" :lg="2" :xl="2"
                ><div class="grid-content bg-purple-light">右侧</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="18" :lg="10" :xl="10"
                ><div class="grid-content" style="text-align: left;">
                  <el-checkbox-group v-model="value[1].rt">
                    <el-checkbox
                      v-for="(item, index) in chu[1].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div></el-col
              >
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">动</div></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content" style="text-align: left;">
                  <el-checkbox-group v-model="value[2].inspect">
                    <el-checkbox
                      v-for="(item, index) in dong[0].value"
                      :label="item"
                      :key="index"
                      :disabled="modify"
                      >{{ item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">肌力</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="4" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">左下肢</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="8" :lg="9" :xl="9">
                <div class="grid-content range">
                  <el-input   type="range"  min="0" max="5" :value="value[2].lt" v-model="value[2].lt" :disabled="modify"> 
                  </el-input>
                  <div class="jibie">
                    {{ value[2].lt }}级
                  </div>
                  </div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="4" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">右下肢</div></el-col
              >
              <el-col :xs="18" :sm="18" :md="8" :lg="9" :xl="9"
                >
                <div class="grid-content range">
                  <el-input  type="range"  min="0" max="5" :value="value[2].rt"  v-model="value[2].rt" :disabled="modify"></el-input>
                  <div class="jibie">
                    {{ value[2].rt }}级
                  </div>
                  </div
                  
              ></el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">量</div></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">左侧</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">周径</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content ">
                  <el-input
                    v-model="value[3].lt[0].diameter"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">大腿围</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content ">
                  <el-input
                    v-model="value[3].lt[0].thigh"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">小腿围</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content">
                  <el-input
                    v-model="value[3].lt[0].calf"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">ROM</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content">
                  <el-input
                    v-model="value[3].lt[0].rom1"
                    :disabled="modify"
                  ></el-input
                  >°<el-input
                    v-model="value[3].lt[0].rom2"
                    :disabled="modify"
                  ></el-input
                  >°<el-input
                    v-model="value[3].lt[0].rom3"
                    :disabled="modify"
                  ></el-input
                  >°
                </div></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                ><div class="grid-content bg-purple">右侧</div></el-col
              >
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">周径</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content bg-purple">
                  <el-input
                    v-model="value[3].rt[0].diameter"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">大腿围</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content ">
                  <el-input
                    v-model="value[3].rt[0].thigh"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">小腿围</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content ">
                  <el-input
                    v-model="value[3].rt[0].calf"
                    :disabled="modify"
                  ></el-input></div
              ></el-col>
              <el-col :xs="6" :sm="6" :md="3" :lg="3" :xl="3"
                ><div class="grid-content bg-purple-light">ROM</div></el-col
              >
              <el-col :xs="18" :sm="6" :md="9" :lg="9" :xl="9"
                ><div class="grid-content">
                  <el-input
                    v-model="value[3].rt[0].rom1"
                    :disabled="modify"
                  ></el-input
                  >°<el-input
                    v-model="value[3].rt[0].rom2"
                    :disabled="modify"
                  ></el-input
                  >°<el-input
                    v-model="value[3].rt[0].rom3"
                    :disabled="modify"
                  ></el-input
                  >°
                </div></el-col
              >
            </el-row>
          </div>
          <div class="modify" v-show="showform">
            <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="modify"></el-button>
            <!-- <el-button type="warning" @click="tomodify" v-show="modify"
              >修改</el-button
            > -->
            <div v-show="!modify" >
              <el-row>
                <el-button type="success" icon="el-icon-check" circle @click="confirm"></el-button>
              </el-row>
              <el-row>
                <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
              </el-row>

            </div>
            <!-- <div class="aamodify" v-show="!modify">
              <el-button type="success" @click="confirm">确定</el-button
              ><el-button type="danger" @click="cancel">取消</el-button>
            </div> -->
          </div>
          <div class="tianxie" v-show="!showform">
            <el-button type="primary" @click="tofill">去填写 </el-button>
          </div>
        </div>
      </template>
            <script>
      import { phy } from "../../util/Process.js";
      import grade from "@/grade/grade.vue"
      export default {
        data() {
          return {
            dialogwid:'60%',
            modify: true,
            operationid: "",
            percentage:20,
            value: [
              { gait: [], lt: [], rt: [], other: "" },
              { mst: [], lt: [], rt: [] },
              { inspect: [], lt: "", rt: "" },
              {
                lt: [
                  { diameter: "", thigh: "", calf: "", rom1: "", rom2: "", rom3: "" },
                ],
                rt: [
                  { diameter: "", thigh: "", calf: "", rom1: "", rom2: "", rom3: "" },
                ],
              },
            ],
            other: false,
            shi: [
              { value: ["防痛", "臂中肌", "跛行", "帕金森", "剪刀", "痉挛", "其他"] },
              { value: ["内翻", "外翻", "无"] },
            ],
            chu: [
              { value: ["正常", "高"] },
              { value: ["内侧", "外侧", "膝前", "膝后"] },
            ],
            dong: [
              {
                value: [
                  "髌骨研磨",
                  "内翻应力",
                  "外翻应力",
                  "Lachman",
                  "ADT",
                  "PDT",
                  "浮髌",
                  "McMurray",
                  "股四头肌抗阻",
                ],
              },
            ],
            checkvalue: [],
            showform: false,
            dialogVisible: false,
            next: 2,
            desc: {},
            selectvalue: "",
          };
        },
        created() {
          this.operationid = this.$route.query.room;
          
        },
        mounted() {
          this.getinfo();
          let wid = window.innerWidth
    // console.log(wid)
      this.dialogwid = wid <=480 ? "90%":wid>=1200 ? "50%":"80%"
        },
        components:{
          grade
        },
        watch: {
          value: {
            handler(value, older) {
              let other = value[0].gait;
              if (other.includes("其他")) {
                this.other = true;
              } else {
                this.other = false;
              }
            },
            deep: true,
          },
        },
        methods: {
          async getinfo() {
            try {
              const { data: res } = await this.$http.get(
                "/Operation/getPreOperationInfo/" + this.operationid,
                {
                  params: {
                    pre_type: "专科体查",
                  },
                }
              );
              if (res.status == "1") {
                if (res.data.pe_vision != null) {
                  this.showform = true;
                  let resvalue = res.data;
                  let aa = phy(resvalue);
                  for (let i = 0; i < aa.length; i++) {
                    if (i != 3) {
                      for (let key in aa[i]) {
                        for (let key2 in this.value[i]) {
                          if ((key2 = key)) {
                            this.value[i][key2] = aa[i][key];
                          }
                        }
                      }
                    } else {
                      for (let key in aa[i]) {
                        let obj = aa[i][key][0];
                        for (let key2 in obj) {
                          this.value[i][key][0][key2] = obj[key2];
                        }
                      }
                    }
                  }
                }
              }
            } catch (err) {}
          },
          // 多选框单机选择，将表单里边的checked转变成true
          changecheckbox(group, option) {
            // option.checked = !option.checked;
            // console.log(group);
            if (group.includes("其他")) {
              this.other = true;
            } else {
              this.other = false;
            }
          },
          // 点击前往步骤填写
          tofill() {
            this.dialogVisible = true;
          },
          // 下一步
          nextlist() {
            this.next = this.next == 7 ? 7 : this.next + 1;
            this.percentage = this.percentage == 100 ? 100: this.percentage+20
          },
          // 上一步
          theprevious() {
            this.next = this.next <= 2 ? 2 : this.next - 1;
            this.percentage = this.percentage <=20 ? 20: this.percentage-20
          },
          // 点击弹窗确定按钮
          async showexamination() {
            this.showform = true;
            this.dialogVisible = false;
            const { data: res } = await this.$http.post(
              "/Operation/addPreOperationInfo/" + this.operationid,
              {
                pe_vision: this.value[0],
                pe_touch: this.value[1],
                pe_dynamic: this.value[2],
                pe_measure: this.value[3],
              }
            );
            if (res.status == "1") {
              this.$message.success("提交成功");
            } else {
              this.$message.error("提交失败");
            }
          },
          // 确定提交
          async confirm() {
            this.modify = true;
            this.dialogVisible = false;
            const { data: res } = await this.$http.post(
              "/Operation/addPreOperationInfo/" + this.operationid,
              {
                pe_vision: this.value[0],
                pe_touch: this.value[1],
                pe_dynamic: this.value[2],
                pe_measure: this.value[3],
              }
            );
            if (res.status == "1") {
              this.$message.success("提交成功");
              this.getinfo();
            }
          },
          // 取消
          cancel() {
            this.modify = true;
          },
          // 去修改
          tomodify() {
            this.modify = false;
          },
          getgrade1(value){
            this.value[2].lt = value
          },  
          getgrade2(value){
            this.value[2].rt = value

          }
        },
      };
      </script>
      
      <style scoped>
      .physical {
        /* position: relative; */
        height: fit-content;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
      }

      .physical .grid-content-left{
        display: flex;
        align-items: center;
        margin: 10px 0;
      }
      .physical .from-topic{
        min-width:80px;
      }
      .physical .el-radio {
        margin: 0px 5px 10px 0px !important; 
        padding: 12px 7px 0 7px !important; ;
      }
      .physical .el-checkbox.is-bordered {
        margin: 0px 5px 0px 0px !important; 
        padding: 10px 7px 0 7px !important; ;
      }
      .mainbody {
        height: fit-content;
        width: 100%;
        box-sizing: border-box;
      }
     .physical .el-col {
        margin-bottom: 5px;
      }
     .physical .titlecol {
        margin-bottom: 5px;
        margin-top: 3px;
      }
      .checkboxcss {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0px 15px;
      }
      .bg-purple-light {
          background: #e5e9f2;
        }
      .forinput {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .forinput label {
        width: 60px;
        box-sizing: border-box;
        padding: 0px 5px;
      }
      .incla {
        margin-top: 10px;
      }
      .modify {
        position: absolute;
        top: 60%;
        right: 15%;
       
      }
      .aamodify{
         display: flex;
        flex-direction: column;
      }
      .aamodify .el-button{
        margin-left:0px;
        margin-bottom: 5px;

      }
      .range .el-input__inner{
        height: 10px !important;
        padding: 0px !important;
        cursor: pointer;
      }
      .jibie{
        width: 70px;
      }
      @media screen and (max-width: 480px) {
        .physical .modifyBtn{
          right:3%;
          top: 75%;
        }
        .physical .grid-content-left {
          display: grid;
          margin: 10px 0 !important;
        }
        .physical .from-topic{
          margin: 10px 0 !important;
          text-align: left !important;
        }
        .physical .el-checkbox.is-bordered.is-disabled{
          float: left;
        }
      }
      </style>