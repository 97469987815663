<template>
  <div class="pastHistory">
    <!-- 分步填写弹窗 -->
    <el-dialog :title="titleValue[next - 1]" :visible.sync="dialogVisible" :width="diawid">
      <el-progress :percentage="percentage"></el-progress>
      <el-form v-model="selectForm">
        <!-- 既往史 -->
        <el-form-item v-show="next == 1">
          <div style="width: 100%; overflow: auto;">
            <div class="grid-content-left">
              <div class="from-topic">既往史：</div>
              <el-checkbox-group
                v-model="operateForm.past_history.checkList"
                @change="handleChange"
                size="medium"
              >
                <el-checkbox 
                v-for="(item, index) in selectForm.selectList" 
                :key="index"
                :label="item" 
                border 
                size="medium" 
                style="margin: 0 5px 10px 0; padding:7px;"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
            <!-- 其他  -->
            <div class="grid-content-left" v-show="otherShow">
                <div class="from-topic">其他为：</div>
                <el-input
                  type="text"
                  v-model="operateForm.past_history.other"
                ></el-input>
            </div>
          </div> 
        </el-form-item>
        <!-- 既往手术史 -->
        <el-form-item v-show="next == 2">
          <!-- 膝关节 -->
          <div style="width: 100%; overflow: auto;" v-if="operation_site==='左膝' || operation_site==='右膝' || operation_site==='单髁'">
              <div class="grid-content-left">
                <div class="from-topic">既往膝关节手术史：</div>
                  <el-radio-group
                  v-model.number="operateForm.previous_surgery_joint"
                  >
                    <el-radio :label="0"  :border="true" >关节镜下清理</el-radio>
                    <el-radio :label="1"  :border="true" >韧带重建</el-radio>
                    <el-radio :label="2"  :border="true" >胫骨高位截骨</el-radio>
                    <el-radio :label="3"  :border="true" >其他</el-radio>
                  </el-radio-group>
              </div>
              <!-- 其他  -->
              <div class="grid-content-left" v-show="operateForm.previous_surgery_joint==3">
                  <div class="from-topic">其他为：</div>
                  <el-input
                    type="text"
                    v-model="operateForm.previous_surgery_input"
                  ></el-input>
              </div>
          </div>
          <!-- 髋关节 -->
          <div style="width: 100%; overflow: auto;" v-if="operation_site==='左髋' || operation_site==='右髋'">
              <div class="grid-content-left">
                <div class="from-topic">既往髋关节手术史：</div>
                  <el-radio-group
                  v-model.number="operateForm.previous_surgery_joint"
                  >
                    <el-radio :label="0"  :border="true" >关节镜下清理</el-radio>
                    <el-radio :label="1"  :border="true" >韧带重建</el-radio>
                    <el-radio :label="2"  :border="true" >胫骨高位截骨</el-radio>
                    <el-radio :label="3"  :border="true" >其他</el-radio>
                  </el-radio-group>
              </div>
              <!-- 其他  -->
              <div class="grid-content-left" v-show="operateForm.previous_surgery_joint==3">
                  <div class="from-topic">其他为：</div>
                  <el-input
                    type="text"
                    v-model="operateForm.previous_surgery_input"
                  ></el-input>
              </div>
          </div> 
        </el-form-item>
        <!-- 手术记录 -->
        <el-form-item v-show="next == 3">
          <div style=" width: 100%; overflow: auto;">
            <div>
                <table style="width: 100%;">
                  <tr>
                    <td style="text-align: center!important; width: 50%;">手术名称</td>
                    <td style="text-align: center!important; width: 25%;">时间(年)</td>
                    <td style="text-align: center!important; width: 25%;">时间(月)</td>
                  </tr>
                  <tr  v-for="(item, index3) in operateForm.surgical_history" :key="'s' + index3">
                    <td><el-input v-model="item.name"/></td>
                    <td><el-date-picker v-model="item.datal" type="year" placeholder="选择年" /></td>
                    <td>
                      <el-select v-model="item.month" placeholder="月份(可选)">
                        <el-option
                          v-for="_item in monthsOptions"
                          :key="_item.value"
                          :label="_item.label"
                          :value="_item.value"
                          >
                        </el-option>
                      </el-select>
                    </td>
                  </tr>
                </table>
            </div>
            <div class="grid-content">
              <el-button type="warning" @click="addOperation">增加</el-button>
              <el-button type="danger"  @click="removeOperation">删除</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backlist" type="warning">上一步</el-button>
        <el-button @click="nextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog" v-show="this.next == 3"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    
    <!-- 展示正文 -->

    <el-row :gutter="10" ref="realForm"  v-show="!showBtn">
      <div style=" padding: 10px 20px; width: 100%; overflow: auto;">
          <div class="grid-content-left">
            <div class="from-topic">既往史：</div>
            <el-checkbox-group
              v-model="operateForm.past_history.checkList"
              @change="handleChange"
              size="medium"
            >
              <el-checkbox 
              v-for="(item, index) in selectForm.selectList" 
              :key="index"
              :label="item" 
              :disabled="modify"
              border 
              size="medium" 
              style="margin: 0 5px 10px 0; padding:7px;"
              ></el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 其他  -->
          <div  class="grid-content-left" v-show="operateForm.past_history.checkList.includes('其他')">
              <div class="from-topic">其他为：</div>
              <el-input
                type="text"
                v-model="operateForm.past_history.other"
                :disabled="modify"
              ></el-input>
          </div>
      </div>  
      <!-- 膝关节 -->
      <div style=" padding: 0px 20px; width: 100%; overflow: auto;" v-if="operation_site==='左膝' || operation_site==='右膝' || operation_site==='单髁'">
          <div class="grid-content-left">
            <div class="from-topic">既往膝关节手术史：</div>
              <el-radio-group
              v-model.number="operateForm.previous_surgery_joint"
              :disabled="modify"
              >
                <el-radio :label="0"  :border="true" >关节镜下清理</el-radio>
                <el-radio :label="1"  :border="true" >韧带重建</el-radio>
                <el-radio :label="2"  :border="true" >胫骨高位截骨</el-radio>
                <el-radio :label="3"  :border="true" >其他</el-radio>
              </el-radio-group>
          </div>
          <!-- 其他  -->
          <div class="grid-content-left" style="margin-top: 10px" v-show="operateForm.previous_surgery_joint==3">
              <div class="from-topic">其他为：</div>
              <el-input
                type="text"
                v-model="operateForm.previous_surgery_input"
                :disabled="modify"
              ></el-input>
          </div>
      </div>
      <!-- 髋关节 -->
      <div style=" padding: 0px 20px; width: 100%; overflow: auto;" v-if="operation_site==='左髋' || operation_site==='右髋'">
          <div class="grid-content-left">
            <div class="from-topic">既往髋关节手术史：</div>
              <el-radio-group
              v-model.number="operateForm.previous_surgery_joint"
              :disabled="modify"
              >
                <el-radio :label="0"  :border="true" >关节镜下清理</el-radio>
                <el-radio :label="1"  :border="true" >韧带重建</el-radio>
                <el-radio :label="2"  :border="true" >胫骨高位截骨</el-radio>
                <el-radio :label="3"  :border="true" >其他</el-radio>
              </el-radio-group>
          </div>
          <!-- 其他  -->
          <div class="grid-content-left" v-show="operateForm.previous_surgery_joint==3">
              <div class="from-topic">其他为：</div>
              <el-input
                type="text"
                v-model="operateForm.previous_surgery_input"
                :disabled="modify"
              ></el-input>
          </div>
      </div> 
      <!-- 手术史 -->
      <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
        <p style="padding:0.3rem; background: #d3dce6; margin-bottom: 0; text-align: center;">手术史</p>
        <div class="grid-content-left">
            <table style="width: 100%;">
              <tr>
                <td style="text-align: center!important; width: 50%;">手术名称</td>
                <td style="text-align: center!important; width: 25%;">时间(年)</td>
                <td style="text-align: center!important; width: 25%;">时间(月)</td>
              </tr>
              <tr  v-for="(item, index3) in operateForm.surgical_history" :key="'s' + index3">
                <td><el-input v-model="item.name" :disabled="modify" /></td>
                <td><el-date-picker v-model="item.datal" type="year" placeholder="选择年" :disabled="modify" /></td>
                <td>
                  <el-select v-model="item.month" :disabled="modify" placeholder="月份(可选)">
                    <el-option
                      v-for="_item in monthsOptions"
                      :key="_item.value"
                      :label="_item.label"
                      :value="_item.value"
                      >
                    </el-option>
                  </el-select>
                </td>
              </tr>
            </table>
        </div>
        <div class="grid-content">
          <el-button type="warning" @click="addOperation" :disabled="modify"
            >增加</el-button
          ><el-button
            type="danger"
            @click="removeOperation"
            :disabled="modify"
            >删除</el-button
          >
        </div>
      </div>
    </el-row>


    <!-- 旧 -->
    <el-form v-model="selectForm" v-show="false">
      <span class="longspan">既往史</span>
      <el-form-item>
        <div class="item-content">
          <el-checkbox-group
            v-model="operateForm.past_history.checkList"
            @change="handleChange"
          >
              <el-checkbox 
              v-for="(item, index) in selectForm.selectList" 
              :key="index"
              :label="item" 
              :disabled="modify"
              :border="true" 
              size="medium" 
              style="margin-left:0;margin-right:5px; padding:7px;"
              ></el-checkbox>
          </el-checkbox-group>
          <el-input
            v-model="operateForm.past_history.other"
            v-show="otherShow"
            :disabled="modify"
            placeholder="请填写其他既往史"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="既往髋关节手术史" label-width="130px" v-if="operation_site==='左髋' || operation_site==='右髋'">
        <div class="el-item-content">
          <el-radio-group
            v-model.number="operateForm.previous_surgery_joint"
            :disabled="modify"
          >
            <el-radio :label="1"  :border="true" >左侧</el-radio>
            <el-radio :label="2"  :border="true" >右侧</el-radio>
            <el-radio :label="3"  :border="true" >双侧</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>

      <el-form-item label="既往膝关节手术史" label-width="130px" v-else>
        <div  class="el-item-content">
          <el-radio-group
            v-model.number="operateForm.previous_surgery_joint"
            :disabled="modify"
          >
            <el-radio :label="0"  :border="true" >关节镜下清理</el-radio>
            <el-radio :label="1"  :border="true" >韧带重建</el-radio>
            <el-radio :label="2"  :border="true" >胫骨高位截骨</el-radio>
            <el-radio :label="3"  :border="true" >其他</el-radio>
          </el-radio-group>
          <el-input v-show="operateForm.previous_surgery_joint==3" v-model="operateForm.previous_surgery_input" style="width:40%;margin-left: 40px;"></el-input>
        </div>
        <!-- <div style="width: fit-content;">
        
        </div> -->
      </el-form-item>

      <span class="longspan mb10">手术史</span>
      <el-row :gutter="10" class="drugname">
        <el-col :span="8"
          ><div class="grid-content bg-purple">手术名称</div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">时间(年)</div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">时间(月)</div></el-col
        >
      </el-row>
      <el-form-item
        v-for="(item, index3) in operateForm.surgical_history"
        :key="'s' + index3"
        class="operationClass"
      >
        <el-row :gutter="10">
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"
            ><div class="grid-content bg-purple">
              <el-input v-model="item.name" :disabled="modify" />
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"
            ><div class="grid-content bg-purple">
              <!-- <el-input v-model="item.datal" :disabled="modify" /> -->
              <el-date-picker v-model="item.datal" type="year" placeholder="选择年" :disabled="modify" /> 
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-select v-model="item.month" :disabled="modify" placeholder="月份(可选)">
              <el-option
                v-for="_item in monthsOptions"
                :key="_item.value"
                :label="_item.label"
                :value="_item.value"
                >
              </el-option>
            </el-select>
          </el-col>
          
        </el-row>
      </el-form-item>
      <el-form-item class="operationClass">
        <el-row :gutter="10">
          <el-col :span="24"
            ><div class="grid-content">
              <el-button type="warning" @click="addOperation" :disabled="modify"
                >增加</el-button
              ><el-button
                type="danger"
                @click="removeOperation"
                :disabled="modify"
                >删除</el-button
              >
            </div></el-col
          >
        </el-row>
      </el-form-item>
    </el-form>
    <el-button class="gofill" type="primary" @click="goFillin" v-show="showBtn">去填写</el-button>
    <div class="modifyBtn" v-show="!showBtn">
      <el-button type="primary" icon="el-icon-edit" circle @click="toModify" v-show="modify"></el-button>
      <!-- <el-button type="warning" @click="toModify" v-show="modify"
        >修改</el-button
      > -->
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>
      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PastHistory",
  data() {
    return {
      operation_id: "",
      operation_site:"",
      otherShow: false,
      diawid: "90%",
      operateForm: {
        past_history: {
          checkList: [],
          other: "",
        },
        previous_surgery_joint: "",
        previous_surgery_input:'',
        surgical_history: [
          { name: "", datal: "" ,month:""},
          { name: "", datal: "" ,month:""},
          { name: "", datal: "" ,month:""},
        ],
      },
      selectForm: {
        selectList: [
          "骨质疏松",
          "类风湿关节炎",
          "椎间盘退变",
          "关节退变",
          "糖尿病(Ⅰ型)",
          "糖尿病(Ⅱ型)",
          "高血压",
          "贫血",
          "血脂异常",
          "慢性肺疾病",
          "肾脏疾病",
          "抑郁症",
          "肿瘤史",
          "低蛋白血症",
          "低血钾",
          "冠心病",
          "皮肤或泌尿道感染",
          "自身免疫病",
          "外周血管疾病及瓣膜病",
          "长期使用激素",
          "其他",
        ],
      },
      modify: true,
      operationHistory: [
        { operationName: "", operationDate: "" },
        { operationName: "", operationDate: "" },
        { operationName: "", operationDate: "" },
      ],
      percentage: 33.3,
      customColors: [
        { color: "#1989fa", percentage: 66 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      showBtn: true,
      nextBtn: true,
      dialogVisible: false,
      next: 1,
      titleValue: ["填写既往史", "填写既往膝关节手术史", "填写手术史"],
      historyOfKneeJoint: 1,
      showOpration: true,
      monthsOptions: [
        {value: '选项1',label: '一月'}, 
        {value: '选项2',label: '二月'}, 
        {value: '选项3',label: '三月'}, 
        {value: '选项4',label: '四月'}, 
        {value: '选项5',label: '五月'}, 
        {value: '选项6',label: '六月'}, 
        {value: '选项7',label: '七月'}, 
        {value: '选项8',label: '八月'}, 
        {value: '选项9',label: '九月'}, 
        {value: '选项10',label: '十月'}, 
        {value: '选项11',label: '十一月'}, 
        {value: '选项12',label: '十二月'}, 
      ],
      value:''
    };
  },

  mounted(){
    let wid = window.innerWidth
    this.diawid = wid <=480 ? '90%':wid >=1200 ? "60%" : '80%'
  },
  created() {
    this.operation_id = this.$route.query.room;
    this.operation_site = this.$route.query.site;
    this.GetPastHistoryInfo();
  },
  mounted() {
    if (this.operateForm.past_history.other == "") {
      this.otherShow = false;
    } else {
      this.otherShow = true;
    }
  },
  methods: {
    // 获取表单请求
    async GetPastHistoryInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Operation/getPreOperationInfo/" + this.operation_id,
          {
            params: { pre_type: "既往史" },
          }
        );
        if (res.status == 0) {
          this.showBtn = true
        }else
        if (res.status == 1) {
          let value = res.data;
            for(let key in value){
              // console.log(value[key]);
              this.operateForm[key] = value[key] ? value[key] : this.operateForm[key]
          }
          // this.operateForm.past_history = res.data.past_history;
          // this.operateForm.previous_surgery_joint =
          //   res.data.previous_surgery_joint;
          // this.operateForm.surgical_history = res.data.surgical_history;
          this.operateForm.previous_surgery_joint =
            +this.operateForm.previous_surgery_joint;
          this.showBtn = false;
        }
        console.log(res, "既往史请求数据");
      } catch (error) {
        // console.log(error);
      }
    },

    // 更改表单请求
    async PostPastHistoryInfo() {
      try {
        const { data: res } = await this.$http.post(
          "/Operation/addPreOperationInfo/" + this.operation_id,
          this.operateForm
        );
        console.log(res)
        if (res.status == 1) {
          this.$message.success("修改成功")
          this.GetPastHistoryInfo()
          console.log(res, "既往史写入数据");
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // 点击获取多选项的值
    handleChange(value) {
      if (value.includes("其他")) {
        this.otherShow = true;
      } else {
        this.otherShow = false;
      }
    },
    // 添加手术史
    addOperation() {
      this.operateForm.surgical_history.push({ name: "", datal: "",month:"" });
    },
    // 移除手术史
    removeOperation() {
      let value = this.operateForm.surgical_history;
      this.operateForm.surgical_history.splice(value.length - 1, 1);
    },
    // 点击去填写初始显示
    goFillin() {
      this.dialogVisible = true;
    },
    // 点击填写下一步
    nextlist() {
      this.next = this.next < 3 ? this.next + 1 : 3;
      if (this.next < 3) {
        this.percentage += 33;
      } else {
        this.percentage = 100;
        this.nextBtn = false;
      }
    },
    // 上一步
    backlist() {
      this.next = this.next < 2 ? 1 : this.next - 1;
      this.percentage = this.percentage > 35 ? this.percentage - 33 : 34;
      this.nextBtn = this.next > 2 ? false : true;
    },
    // dialog确定
    closedialog() {
      this.dialogVisible = false;
      this.showBtn = false;
      this.PostPastHistoryInfo();
    },
    // 点击修改表单
    toModify() {
      this.modify = !this.modify;
    },
    // 点击确定修改
    sure() {
      this.modify = !this.modify;
      this.PostPastHistoryInfo();
    },
    // 点击取消修改
    cancel() {
      this.modify = !this.modify;
    },
  },
};
</script>

<style>
.pastHistory {
  /* position: relative; */
  height: fit-content;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  /* padding: 10px; */
}
.el-form-item {
  margin-bottom: 10px;
}
.pastHistory .grid-content-left{
  display: flex;
  align-items: baseline;
  /* margin: 10px 0; */
}
.pastHistory .from-topic{
  min-width:90px;
}
.longspan {
  display: block;
  width: 100%;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  background-color: #616876;
}

.el-radio-group label {
  margin-bottom: 0;
}
.pastHistory .el-radio {
  margin-bottom: 0;
  margin-right:0;
}

.pastHistory .el-form-item .el-form-item__label {
  color: #fff !important;
  border-radius: 4px 0 0 4px !important;
  background-color: #616876 !important;
  text-align: center !important;
  padding: 0 5px 0 5px !important;
  margin-bottom: 0 !important;
}
.item-content {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 10px;
}
.el-item-content {
  border: 1px solid #dcdfe6;
  border-radius: 0 4px 4px 0;
  padding: 0px 0 0 10px;
  height: 40px !important;
}
.pastHistory .grid-content {
  border-radius: 4px !important;
  min-height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.bg-purple {
  background: #d3dce6;
}
.addOpration {
  margin-bottom: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.pastHistory .modifyBtn {
  position: absolute;
  /* height: 30px;
  width: fit-content;
  box-sizing: border-box; */
  /* padding: 0px 10px; */
  /* border-radius: 4px; */
  right: 15%;
  top: 60%;
}
@media screen and (max-width: 480px) {
  .pastHistory .modifyBtn{
    right:5%;
    top: 75%;
  }
  .pastHistory .grid-content-left {
    display: contents;
    align-items: baseline;
    margin: 10px 0;
  }
  .pastHistory .from-topic{
    margin: 10px 0;
  }
}

.el-progress {
  margin-bottom: 15px;
}
.pastHistory .el-date-editor.el-input{
  width: 100%;
}
.pastHistory .el-select{
  width: 100%;
}
.pastHistory .gofill{
  position: absolute;
  left: 50%;
  margin-left: -42px;
}
</style>