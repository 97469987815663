<template>
  <div class="excel">
  <div class="uploaddiv">
    <el-upload
                ref="upload" 
                class="upload-demo"
                drag
                action
                :limit="1"
                :show-file-list="false"
                :http-request="getExcel"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
  </div>
  <div class="errProvines" v-show="errprovince.length !=0">
    <el-table
    border
    :header-cell-style="{ textAlign: 'center'}"
    :cell-style="{ textAlign: 'center' }"   
    :data="errprovince"
    style="width: 100%"
    height="100%">
    <el-table-column
      fixed
      type="index"
      label="序号"
      width="80px"
     >
     
    </el-table-column>
    <el-table-column
      fixed
      prop="编号"
      label="编号"
     >
     
    </el-table-column>
    <el-table-column
      prop="姓名"
      label="姓名"
      >
    </el-table-column>
    <el-table-column
      prop="性别"
      label="性别"
      >
    </el-table-column>
    <el-table-column
      prop="出生日期"
      label="出生日期"
      >
      
    </el-table-column>
    <el-table-column
      prop="籍贯"
      label="籍贯"
      width="300">
      <template slot-scope="scope">
        <div :class="{'redcolor': scope.row.籍贯.value ? true : false}">
        {{ scope.row.籍贯.value ?scope.row.籍贯.value:scope.row.籍贯  }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="家庭住址"
      label="家庭住址"
      width="400">
      <template slot-scope="scope">
        <div :class="{'redcolor' :  scope.row.家庭住址.value?true:false}">
        {{ scope.row.家庭住址.value ? scope.row.家庭住址.value:scope.row.家庭住址 }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="身份证号"
      label="身份证号"
     >
    </el-table-column>
    <el-table-column
      prop="本人联系电话"
      label="本人联系电话"
     >
    </el-table-column>
  </el-table>
  </div>
        <el-button @click="updateProvinceAndCityCodes"  v-loading.fullscreen.lock="fullscreenLoading">测试</el-button>
  </div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';
import {chuli} from '../../util/Process'
import { regionData,provinceAndCityData } from 'element-china-area-data';
export default {
    data(){
        // console.log('areaData:', provinceAndCityData);
        return{
            excelvalue:[],
            rightprovince:[],
            errprovince:[],
            regionData, // 使用整个区域数据
            provinceAndCityData, // 使用省市数据,
            beijingCode:'',
            fullscreenLoading: false
        }
    },  
    methods:{
        updateProvinceAndCityCodes() {
            // 遍历 rightprovince 数组
            if(this.rightprovince.length !=0){
            this.rightprovince.forEach(obj => {
            // 获取籍贯并分割
            let [province, city] = obj.籍贯.split(',');
            let [fprovince, fcity,fcounty,fadd] = obj.家庭住址.split(',');
            // 查找省份和城市的编码
            let provinceCode = this.findCode(province, this.provinceAndCityData);
            let fprovinceCode = this.findCode(fprovince, this.provinceAndCityData);
            let cityCode = this.findCode(city, this.regionData);
            let fcityCode = this.findCode(fcity, this.regionData);
            let fcountyCode = this.findCode(fcounty, this.regionData);
            let value = [fprovinceCode,fcityCode,fcountyCode]
            // let code =provinceCode+','+cityCode
            obj.籍贯 = [provinceCode,cityCode]
            obj.家庭住址={add:fadd,value:value}
            });
          }else{
            this.$message.error('数据为空，请重新上传')
          }
            // console.log(this.rightprovince)
            this.resend(this.rightprovince)
        },
        t(){
            for(let i=0;i<this.rightprovince.length;i++){
                let obj = this.rightprovince[i],codearr = []
                let splitvalue = obj.籍贯
                let ressplite = splitvalue.split(',')
                let provincecode = this.findCode(ressplite[0], this.provinceAndCityData)
                let citycode = this.findCode(ressplite[1], this.regionData);
                let code = provincecode+','+citycode
                codearr.push(code)
                obj.籍贯 = codearr
            }
            this.resend(this.rightprovince)
        },
       async resend(value){
        this.fullscreenLoading = true;
            const {data:res} = await this.$http.put('/operation/ImpOperationInfo',{
                info:value
            })
           if(res){
            console.log(res)
            this.fullscreenLoading = false;
           }
        },
        findCode(name, data) {
            for (const key in data) {
            if (data[key].label === name) {
                return data[key].value;
            }
            if (data[key].children) {
                const result = this.findCode(name, data[key].children);
                if (result) return result;
            }
            }
            return null;
        },
        // 导入文件
        getExcel(file) {
        this.dialogVisible=false
        const types = file.file.name.split(".")[1]; //获取文件后缀
        const fileType = ["xlsx","xls"].some(
        item => item === types
        );
        if (!fileType) {
        this.$message("格式错误！请重新选择");
        return;
        }
        this.analysis(file).then(tableJson => {
        if (tableJson && tableJson.length > 0) {
            //成功解析出数据
            this.isNull = false
            let dataExcel = tableJson[0];
            let getvalue=JSON.stringify(dataExcel.sheet)
            let reschuli=chuli(JSON.parse(getvalue))
            this.errprovince = reschuli.errprivin
            this.rightprovince = reschuli.rightprivin
            this.$refs.upload.clearFiles();
        }
        })
        },
        //解析excel   
        analysis(file) {
        return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            let datajson = XLSX.read(data, {
            type: "binary"
            });
            const result = [];
            datajson.SheetNames.forEach(sheetName => {
            result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(datajson.Sheets[sheetName])
            });
            });
            resolve(result);
        };
        reader.readAsBinaryString(file.file);
        });
        },
    }
}
</script>

<style>
.excel{
    /* background-color: antiquewhite; */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
.uploaddiv{
   
    height: 240px;
    border-bottom: 1px solid rgb(19, 1, 1);
}
.errProvines{
    margin-top: 10px;
    /* border: 1px solid red; */
    height: calc( 100% - 300px );
    border-bottom: 1px solid rgb(19, 1, 1);

}
.redcolor{
  color: red;
}
</style>