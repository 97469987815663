<template>
  <div class="PresentHistory">
    <!-- 分步填写 -->
    <el-dialog :title="'请填写'+titleValue[next-1]" :visible.sync="dialogVisible" :width="diawid">
      <el-form ref="form" :model="formData" label-width="130px">
        <el-progress :percentage="percentage"></el-progress>

        <div v-show="next == 1">
          <div style=" padding: 10px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left">
                <div class="from-topic">受累关节：</div>
                <el-radio-group
                  v-model="formData.damaged_joint.location"
                  size="medium"
                >
                  <el-radio label="1" :border ="true" >左侧</el-radio>
                  <el-radio label="2" :border ="true" >右侧</el-radio>
                  <el-radio label="3" :border ="true" >双侧</el-radio>
                </el-radio-group>
              </div>

              <!-- 膝关节疼痛时间 -->
              <div v-if="operation_site!='左髋' || operation_site!='右髋'">
                <div v-show="formData.damaged_joint.location == '1' || formData.damaged_joint.location=='3'">
                  <div class="grid-content-left">
                    <div class="from-topic">左侧疼痛时间：</div>
                    <el-input type="text"   v-model="formData.damaged_joint.datal.date"></el-input>
                  </div>
                </div>
                <div v-show="formData.damaged_joint.location == '2' || formData.damaged_joint.location=='3'">
                  <div class="grid-content-left" >
                    <div class="from-topic">右侧疼痛时间：</div>
                    <el-input type="text" :disabled="modify"  v-model="formData.damaged_joint.datal.dater"></el-input>
                  </div>
                </div>
              </div>
              <!-- 髋关节疼痛时间 -->
              <div v-if="operation_site =='左髋' || operation_site =='右髋'">
                <div class="grid-content-left" v-show="formData.damaged_joint.location == '1' || formData.damaged_joint.location=='3'">
                    <div class="from-topic">左侧疼痛部位：</div>
                    <el-radio-group
                      v-model="formData.damaged_joint.datal.loca"
                      style="padding-top: 5px"
                    >
                      <el-radio label="前" :border ="true" >前</el-radio>
                      <el-radio label="侧" :border ="true" >侧</el-radio>
                      <el-radio label="后" :border ="true" >后</el-radio>
                    </el-radio-group>
                    <div  style="margin-left: 10px;">疼痛时间：</div>
                    <el-input style="width: auto;" v-model="formData.damaged_joint.datal.date"></el-input>
                  </div>

                  <div class="grid-content-left" v-show="formData.damaged_joint.location == '2' || formData.damaged_joint.location=='3'">
                    <div class="from-topic">右侧疼痛部位：</div>
                    <el-radio-group
                     v-model="formData.damaged_joint.datar.loca" 
                      style="padding-top: 5px"
                    >
                      <el-radio label="前" :border ="true" >前</el-radio>
                      <el-radio label="侧" :border ="true" >侧</el-radio>
                      <el-radio label="后" :border ="true" >后</el-radio>
                    </el-radio-group>
                    <div style="margin-left: 10px;">疼痛时间：</div>
                    <el-input style="width: auto;"  v-model="formData.damaged_joint.datar.date"></el-input>
                  </div>
              </div>
            </div>
        </div>

        <div v-show="next == 2">
          <!-- 膝关节诱因 -->
          <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-if="operation_site =='左膝' || operation_site =='左膝' || operation_site =='单髁'">
              <div class="grid-content-left" >
                <div class="from-topic">是否有诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether"
                  style="padding-top: 5px"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  v-show="formData.inducement.whether == 2"
                  v-model="formData.inducement.reason"
                ></el-input>
              </div>
            </div>

            <!-- 髋关节诱因 -->
            <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-if="operation_site =='左髋' || operation_site =='右髋'">
              <div class="grid-content-left" >
                <div class="from-topic">左侧诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether"
                  style="padding-top: 5px"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  v-show="formData.inducement.whether == 2"
                  v-model="formData.inducement.reason"
                ></el-input>
              </div>
              <div class="grid-content-left" >
                <div class="from-topic">右侧诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether1"
                  style="padding-top: 5px"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether1 == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  v-show="formData.inducement.whether1 == 2"
                  v-model="formData.inducement.reason1"
                ></el-input>
              </div>
            </div>
        </div>

        <div v-show="next == 3">
          <div class="grid-content-left">
            <div class="from-topic">外伤史：</div>
            <el-radio-group
              v-model="formData.traumatism.whether" 
            >
              <el-radio label="1" :border ="true" >无</el-radio>
              <el-radio label="2" :border ="true" >有</el-radio>
            </el-radio-group>
            
            <div class="from-topic"  v-show="formData.traumatism.whether == 2" >受伤位置：</div>
              <el-radio-group
                v-show="formData.traumatism.whether == 2" 
                v-model="formData.traumatism.location"  
              >
                <el-radio label="左膝" :border ="true" >左膝</el-radio>
                <el-radio label="右膝" :border ="true" >右膝</el-radio>
              </el-radio-group>
          </div>
          <div v-show="formData.traumatism.whether == 2">
              <div class="grid-content-left" >
                <div class="from-topic">受伤时间：</div>
                <el-date-picker
                  type="month"
                  placeholder="受伤时间"
                  value-format="yyyy-MM-dd"
                  size="large"
                  v-model="formData.traumatism.datal"
                  v-show="formData.traumatism.whether == 2"
                ></el-date-picker>
                
              </div>
            
            
              <div class="grid-content-left" >
                  <div class="from-topic">受伤机制：</div>
                  <el-radio-group
                    v-model="formData.traumatism.mechanism.opt" 
                  >
                    <el-radio label="1" :border ="true" >摔伤</el-radio>
                    <el-radio label="2" :border ="true" >起跳落地扭伤</el-radio>
                    <el-radio label="3" :border ="true" >外力撞伤</el-radio>
                    <el-radio label="4" :border ="true" >车祸伤</el-radio>
                    <el-radio label="5" :border ="true" >其他</el-radio>
                </el-radio-group>
              </div>
            
              <div class="grid-content-left" v-show="formData.traumatism.mechanism.opt == 5">
                <div class="from-topic">其他为：</div>
                  <el-input
                    class="mgb10"
                    placeholder="请输入内容"
                    v-model="formData.traumatism.mechanism.other"
                  ></el-input>
              </div>
              <div class="grid-content-left" >
                <div class="from-topic">伤后关节肿胀：</div>
                  <el-radio-group
                    v-model="formData.traumatism.swollen" 
                    >
                      <el-radio label="1" :border ="true" >否</el-radio>
                      <el-radio label="2" :border ="true" >是</el-radio>
                      <el-radio label="3" :border ="true" >不清楚</el-radio>
                  </el-radio-group>
              </div>    
              <div class="grid-content-left">
                  <div class="from-topic">关节疼痛：</div>
                  <el-radio-group
                    v-model="formData.traumatism.pain"
                  >
                      <el-radio label="1" :border ="true" >否</el-radio>
                      <el-radio label="2" :border ="true" >轻微</el-radio>
                      <el-radio label="3" :border ="true" >严重</el-radio>
                      <el-radio label="4" :border ="true" >不清楚</el-radio>
                  </el-radio-group>
              </div>
              <div class="grid-content-left">
                  <div class="from-topic">伤时能否负重站立：</div>
                  <el-radio-group
                    v-model="formData.traumatism.stand"
                  >
                      <el-radio label="1" :border ="true" >否</el-radio>
                      <el-radio label="2" :border ="true" >轻微</el-radio>
                      <el-radio label="3" :border ="true" >不清楚</el-radio>
                  </el-radio-group>
              </div>
              <div class="grid-content-left">
                  <div class="from-topic">伤时能否负重行走：</div>
                  <el-radio-group
                    v-model="formData.traumatism.walk"
                  >
                      <el-radio label="1" :border ="true" >否</el-radio>
                      <el-radio label="2" :border ="true" >轻微</el-radio>
                      <el-radio label="3" :border ="true" >不清楚</el-radio>
                  </el-radio-group>
              </div>
              <div class="grid-content-left">
                <div class="from-topic">是否治疗：</div>
                <el-radio-group
                  v-model="formData.traumatism.therapy.opt"
                >
                  <el-radio label="1" :border ="true" >否</el-radio>
                  <el-radio label="2" :border ="true" >是</el-radio>
                </el-radio-group>
              </div>
              <div v-show="formData.traumatism.therapy.opt == 2">
                <div class="grid-content-left" style="margin-top:10px;">
                  <div class="from-topic">治疗方式：</div>
                  <el-checkbox-group
                    v-model="formData.traumatism.therapy.way"
                    @change="therapyChange"
                  >
                      <el-checkbox label="冰敷" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="热敷" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="西药" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="中药" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="手术治疗" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="grid-content-left" v-show="formData.traumatism.therapy.operation.show">
                  <div class="from-topic">手术名称：</div>
                  <el-input
                    type="text"
                    v-model="formData.traumatism.therapy.operation.value"
                  ></el-input>
                </div>
                <div class="grid-content-left" v-show="formData.traumatism.therapy.other.show">
                  <div class="from-topic">其他治疗方式：</div>
                  <el-input
                    type="text"
                    v-model="formData.traumatism.therapy.other.value"
                  ></el-input>
                </div>
              </div>
              <div class="grid-content-left" >
                <div class="from-topic">伤后关节疼痛：</div>
                  <el-radio-group
                    v-model="formData.traumatism.postinjury_pain"
                  >
                      <el-radio label="1" :border ="true" >不痛</el-radio>
                      <el-radio label="2" :border ="true" >偶痛</el-radio>
                      <el-radio label="3" :border ="true" >常痛</el-radio>
                  </el-radio-group>
              </div>  
              <div class="grid-content-left" >
                <div class="from-topic">伤后运动能力：</div>
                  <el-radio-group
                    v-model="formData.traumatism.athletic"
                  >
                      <el-radio label="1" :border ="true" >不影响</el-radio>
                      <el-radio label="2" :border ="true" >稍下降</el-radio>
                      <el-radio label="3" :border ="true" >明显下降</el-radio>
                  </el-radio-group>
              </div>  
            </div>
        </div>
        <div v-show="next == 4">
          <div class="grid-content-left" >
            <div class="from-topic">治疗情况：</div>
            <el-radio-group
              v-model="formData.treatment.whether"
              style="padding-top: 5px"
            >
              <el-radio label="1" :border ="true" >无</el-radio>
              <el-radio label="2" :border ="true" >有</el-radio>
            </el-radio-group>
          </div>
          <div v-show="formData.treatment.whether == 2">
            <div class="grid-content-left" style="margin-top:10px;">
              <div class="from-topic">药物：</div>
              <el-checkbox-group
                v-model="formData.treatment.drugs.opt"
                @change="otherChange($event, 'drugs')"
              >
                  <el-checkbox label="NSAIDs外用" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="NSAIDs口服" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="氨糖" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="中药外用" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="中药内服" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="grid-content-left" v-show="formData.treatment.drugs.other.show">
              <div class="from-topic">其他药物：</div>
              <el-input
                type="text"
                v-model="formData.treatment.drugs.other.value"
              ></el-input>
            </div>
            <div class="grid-content-left" style="margin-top:10px;">
              <div class="from-topic">康复理疗：</div>
              <el-checkbox-group
                v-model="formData.treatment.physio.opt"
                @change="otherChange($event, 'physio')"
              >
                  <el-checkbox label="电疗" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="按摩" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="针灸" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="小针刀" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="艾灸" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="冲击波" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="grid-content-left" v-show="formData.treatment.physio.other.show">
              <div class="from-topic">其他康复理疗：</div>
              <el-input
                type="text"
                v-model="formData.treatment.physio.other.value"
              ></el-input>
            </div>

            <div class="grid-content-left" style="margin-top:10px;">
              <div class="from-topic">关节穿刺：</div>
              <el-checkbox-group
                v-model="formData.treatment.puncture.opt"
                @change="punctureChange"
              >
                  <el-checkbox label="玻璃酸钠" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="激素" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
              </el-checkbox-group>
            </div>
            <!-- 玻璃酸钠  -->
            <div class="grid-content-left" v-show="formData.treatment.puncture.ha.show">
              <div class="from-topic">玻璃酸钠数量：</div>
              <el-input
                type="text"
                v-model="formData.treatment.puncture.ha.needle"
              ><template slot="append">针</template></el-input>
              <div class="from-topic" style="margin-left: 10px;">上一次注射时间：</div>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formData.treatment.puncture.ha.lastDate"
                >
              </el-date-picker>
            </div>
            <!-- 激素  -->
            <div class="grid-content-left" v-show="formData.treatment.puncture.ho.show">
              <div class="from-topic">激素数量：</div>
              <el-input
                type="text"
                v-model="formData.treatment.puncture.ho.times"
              ><template slot="append">次</template></el-input>
            </div>
            <!-- 其他  -->
            <div class="grid-content-left" v-show="formData.treatment.puncture.other.show">
              <div class="from-topic">其他为：</div>
              <el-input
                type="text"
                v-model="formData.treatment.puncture.other.value"
              ></el-input>
            </div>

            <div class="grid-content-left" style="margin-top:10px;">
              <div class="from-topic">手术</div>
              <el-checkbox-group
                v-model="formData.treatment.operation.opt"
                @change="operationChange"
              >
                  <el-checkbox label="关节镜下关节清理" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="截骨矫形" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                  <el-checkbox label="无" :border ="true" size="medium"></el-checkbox>
              </el-checkbox-group>
            </div>
            <!-- 关节清理手术时间  -->
            <div class="grid-content-left" v-show="formData.treatment.operation.addate.show">
              <div class="from-topic">关节清理手术时间：</div>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formData.treatment.operation.addate.value"
                >
              </el-date-picker>
            </div>
            <!-- 截骨矫形手术时间  -->
            <div class="grid-content-left" v-show="formData.treatment.operation.ostdate.show">
              <div class="from-topic">截骨矫形手术时间：</div>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formData.treatment.operation.ostdate.value"
                >
              </el-date-picker>
            </div>
            <!-- 其他  -->
            <div class="grid-content-left" v-show="formData.treatment.operation.other.show">
              <div class="from-topic">其他为：</div>
              <el-input
                type="text"
                v-model="formData.treatment.operation.other.value"
              ></el-input>
              <div class="from-topic" style="margin-left: 10px;">手术时间：</div>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formData.treatment.operation.other.date"
                >
              </el-date-picker>
            </div>
          </div>
        </div>

        <div v-show="next == 5">
          <div class="grid-content-left">
            <div class="from-topic">上下楼梯是否需扶手：</div>
            <el-radio-group v-model="formData.staircase">
                <el-radio label="1" :border ="true" >无法上下楼</el-radio>
                <el-radio label="2" :border ="true" >上楼需要</el-radio>
                <el-radio label="3" :border ="true" >下楼需要</el-radio>
                <el-radio label="4" :border ="true" >上下楼均需</el-radio>
                <el-radio label="5" :border ="true" >不需扶手</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div v-show="next == 6">
          <div class="grid-content-left">
            <div class="from-topic">平地行走最远距离：</div>
            <el-input
              placeholder="请输入内容"
              v-model.number="formData.walking_distance"
            >
              <template slot="append">米</template>
            </el-input>
          </div>
        </div>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backlist" type="warning" v-show="backBtn"
          >上一步</el-button
        >
        <el-button @click="nextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog" v-show="this.next == 6"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 正文展示 -->

    <el-row :gutter="10" ref="realForm" :model="formData" v-show="!showBtn">
      
            <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left">
                <div class="from-topic">受累关节：</div>
                <el-radio-group
                  :disabled="modify"
                  v-model="formData.damaged_joint.location"
                  size="medium"
                >
                  <el-radio label="1" :border ="true" >左侧</el-radio>
                  <el-radio label="2" :border ="true" >右侧</el-radio>
                  <el-radio label="3" :border ="true" >双侧</el-radio>
                </el-radio-group>
              </div>

              <!-- 膝关节疼痛 -->
              <div v-if="operation_site =='左膝' || operation_site =='右膝' || operation_site =='单髁'">
                <div v-show="formData.damaged_joint.location == '1' || formData.damaged_joint.location=='3'">
                  <div class="grid-content-left">
                    <div class="from-topic">左侧疼痛时间：</div>
                    <el-input type="text" :disabled="modify"  v-model="formData.damaged_joint.datal.date"></el-input>
                  </div>
                </div>
                <div v-show="formData.damaged_joint.location == '2' || formData.damaged_joint.location=='3'">
                  <div class="grid-content-left" >
                    <div class="from-topic">右侧疼痛时间：</div>
                    <el-input type="text" :disabled="modify"  v-model="formData.damaged_joint.datal.dater"></el-input>
                  </div>
                </div>
              </div>
              <!-- 髋关节疼痛 -->
              <div v-if="operation_site =='左髋' || operation_site =='右髋'">
                
                  <div class="grid-content-left" v-show="formData.damaged_joint.location == '1' || formData.damaged_joint.location=='3'">
                    <div class="from-topic">左侧疼痛部位：</div>
                    <el-radio-group
                      v-model="formData.damaged_joint.datal.loca"
                      style="padding-top: 5px"
                      :disabled="modify"
                    >
                      <el-radio label="前" :border ="true" >前</el-radio>
                      <el-radio label="侧" :border ="true" >侧</el-radio>
                      <el-radio label="后" :border ="true" >后</el-radio>
                    </el-radio-group>
                    <div  style="margin-left: 10px;">疼痛时间：</div>
                    <el-input style="width: auto;" :disabled="modify" v-model="formData.damaged_joint.datal.date"></el-input>
                  </div>

                  <div class="grid-content-left" v-show="formData.damaged_joint.location == '2' || formData.damaged_joint.location=='3'">
                    <div class="from-topic">右侧疼痛部位：</div>
                    <el-radio-group
                     v-model="formData.damaged_joint.datar.loca" 
                      style="padding-top: 5px"
                      :disabled="modify"
                    >
                      <el-radio label="前" :border ="true" >前</el-radio>
                      <el-radio label="侧" :border ="true" >侧</el-radio>
                      <el-radio label="后" :border ="true" >后</el-radio>
                    </el-radio-group>
                    <div style="margin-left: 10px;">疼痛时间：</div>
                    <el-input style="width: auto;" :disabled="modify"  v-model="formData.damaged_joint.datar.date"></el-input>
                  </div>

              </div>

            </div>

            <!-- 膝关节诱因 -->
            <div style=" padding: 0px 20px; width: 100%; overflow: auto;" v-if="operation_site =='左膝' || operation_site =='左膝' || operation_site =='单髁'">
              <div class="grid-content-left" >
                <div class="from-topic">是否有诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether"
                  style="padding-top: 0px"
                  :disabled="modify"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  :disabled="modify"
                  v-show="formData.inducement.whether == 2"
                  v-model="formData.inducement.reason"
                ></el-input>
              </div>
            </div>

            <!-- 髋关节诱因 -->
            <div style=" padding: 0px 20px; width: 100%; overflow: auto;" v-if="operation_site =='左髋' || operation_site =='右髋'">
              <div class="grid-content-left" >
                <div class="from-topic">左侧诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether"
                  style="padding-top: 0px"
                  :disabled="modify"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  :disabled="modify"
                  v-show="formData.inducement.whether == 2"
                  v-model="formData.inducement.reason"
                ></el-input>
              </div>
              <div class="grid-content-left" >
                <div class="from-topic">右侧诱因：</div>
                <el-radio-group
                  v-model="formData.inducement.whether1"
                  style="padding-top: 5px"
                  :disabled="modify"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                <div style="margin-left:10px;" v-show="formData.inducement.whether1 == 2">诱因为：</div>
                <el-input
                  type="text"
                  placeholder="诱因"
                  class="flex1 mgl10"
                  :disabled="modify"
                  v-show="formData.inducement.whether1 == 2"
                  v-model="formData.inducement.reason1"
                ></el-input>
              </div>
            </div>

            <!-- 外伤史 -->
            <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left">
                <div class="from-topic">外伤史：</div>
                <el-radio-group
                  v-model="formData.traumatism.whether" 
                  :disabled="modify"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
                
                <div class="from-topic"  v-show="formData.traumatism.whether == 2" >受伤位置：</div>
                  <el-radio-group
                    v-show="formData.traumatism.whether == 2" 
                    v-model="formData.traumatism.location"  
                    :disabled="modify"
                  >
                    <el-radio label="左膝" :border ="true" >左膝</el-radio>
                    <el-radio label="右膝" :border ="true" >右膝</el-radio>
                  </el-radio-group>
              </div>

             
              <div v-show="formData.traumatism.whether == 2">
                <div class="grid-content-left" >
                  <div class="from-topic">受伤时间：</div>
                  <el-date-picker
                    type="month"
                    placeholder="受伤时间"
                    value-format="yyyy-MM-dd"
                    size="large"
                    v-model="formData.traumatism.datal"
                    v-show="formData.traumatism.whether == 2"
                    :disabled="modify"
                  ></el-date-picker>
                  
                </div>
              
             
                <div class="grid-content-left" >
                    <div class="from-topic">受伤机制：</div>
                    <el-radio-group
                      v-model="formData.traumatism.mechanism.opt" 
                      :disabled="modify"
                    >
                      <el-radio label="1" :border ="true" >摔伤</el-radio>
                      <el-radio label="2" :border ="true" >起跳落地扭伤</el-radio>
                      <el-radio label="3" :border ="true" >外力撞伤</el-radio>
                      <el-radio label="4" :border ="true" >车祸伤</el-radio>
                      <el-radio label="5" :border ="true" >其他</el-radio>
                  </el-radio-group>
                </div>
              
                <div class="grid-content-left" v-show="formData.traumatism.mechanism.opt == 5">
                  <div class="from-topic">其他为：</div>
                    <el-input
                      class="mgb10"
                      placeholder="请输入内容"
                      v-model="formData.traumatism.mechanism.other"
                    ></el-input>
                </div>
                <div class="grid-content-left" >
                  <div class="from-topic">伤后关节肿胀：</div>
                    <el-radio-group
                      v-model="formData.traumatism.swollen" 
                        :disabled="modify"
                      >
                        <el-radio label="1" :border ="true" >否</el-radio>
                        <el-radio label="2" :border ="true" >是</el-radio>
                        <el-radio label="3" :border ="true" >不清楚</el-radio>
                    </el-radio-group>
                </div>    
                <div class="grid-content-left">
                    <div class="from-topic">关节疼痛：</div>
                    <el-radio-group
                      v-model="formData.traumatism.pain"
                      :disabled="modify"
                    >
                        <el-radio label="1" :border ="true" >否</el-radio>
                        <el-radio label="2" :border ="true" >轻微</el-radio>
                        <el-radio label="3" :border ="true" >严重</el-radio>
                        <el-radio label="4" :border ="true" >不清楚</el-radio>
                    </el-radio-group>
                </div>
                <div class="grid-content-left">
                    <div class="from-topic">伤时能否负重站立：</div>
                    <el-radio-group
                      v-model="formData.traumatism.stand"
                      :disabled="modify"
                    >
                        <el-radio label="1" :border ="true" >否</el-radio>
                        <el-radio label="2" :border ="true" >轻微</el-radio>
                        <el-radio label="3" :border ="true" >不清楚</el-radio>
                    </el-radio-group>
                </div>
                <div class="grid-content-left">
                    <div class="from-topic">伤时能否负重行走：</div>
                    <el-radio-group
                      v-model="formData.traumatism.walk"
                      :disabled="modify"
                    >
                        <el-radio label="1" :border ="true" >否</el-radio>
                        <el-radio label="2" :border ="true" >轻微</el-radio>
                        <el-radio label="3" :border ="true" >不清楚</el-radio>
                    </el-radio-group>
                </div>
                <div class="grid-content-left">
                  <div class="from-topic">是否治疗：</div>
                  <el-radio-group
                    :disabled="modify"
                    v-model="formData.traumatism.therapy.opt"
                  >
                    <el-radio label="1" :border ="true" >否</el-radio>
                    <el-radio label="2" :border ="true" >是</el-radio>
                  </el-radio-group>
                </div>
                <div v-show="formData.traumatism.therapy.opt == 2">
                  <div class="grid-content-left" style="margin-top:0px;">
                    <div class="from-topic">治疗方式：</div>
                    <el-checkbox-group
                      v-model="formData.traumatism.therapy.way"
                      @change="therapyChange"
                      :disabled="modify"
                    >
                        <el-checkbox label="冰敷" :border ="true" size="medium"></el-checkbox>
                        <el-checkbox label="热敷" :border ="true" size="medium"></el-checkbox>
                        <el-checkbox label="西药" :border ="true" size="medium"></el-checkbox>
                        <el-checkbox label="中药" :border ="true" size="medium"></el-checkbox>
                        <el-checkbox label="手术治疗" :border ="true" size="medium"></el-checkbox>
                        <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="grid-content-left" v-show="formData.traumatism.therapy.operation.show">
                    <div class="from-topic">手术名称：</div>
                    <el-input
                      type="text"
                      v-model="formData.traumatism.therapy.operation.value"
                      :disabled="modify"
                    ></el-input>
                  </div>
                  <div class="grid-content-left" v-show="formData.traumatism.therapy.other.show">
                    <div class="from-topic">其他治疗方式：</div>
                    <el-input
                      type="text"
                      v-model="formData.traumatism.therapy.other.value"
                      :disabled="modify"
                    ></el-input>
                  </div>
                </div>
                <div class="grid-content-left" >
                  <div class="from-topic">伤后关节疼痛：</div>
                    <el-radio-group
                      v-model="formData.traumatism.postinjury_pain"
                      :disabled="modify"
                    >
                        <el-radio label="1" :border ="true" >不痛</el-radio>
                        <el-radio label="2" :border ="true" >偶痛</el-radio>
                        <el-radio label="3" :border ="true" >常痛</el-radio>
                    </el-radio-group>
                </div>  
                <div class="grid-content-left" >
                  <div class="from-topic">伤后运动能力：</div>
                    <el-radio-group
                      v-model="formData.traumatism.athletic"
                      :disabled="modify"
                    >
                        <el-radio label="1" :border ="true" >不影响</el-radio>
                        <el-radio label="2" :border ="true" >稍下降</el-radio>
                        <el-radio label="3" :border ="true" >明显下降</el-radio>
                    </el-radio-group>
                </div>  
              </div>
            </div>

            <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left" >
                <div class="from-topic">治疗情况：</div>
                <el-radio-group
                  v-model="formData.treatment.whether"
                  style="padding-top: 0px"
                  :disabled="modify"
                >
                  <el-radio label="1" :border ="true" >无</el-radio>
                  <el-radio label="2" :border ="true" >有</el-radio>
                </el-radio-group>
              </div>
              <div v-show="formData.treatment.whether == 2">
                <div class="grid-content-left" style="margin-top:0px;">
                  <div class="from-topic">药物：</div>
                  <el-checkbox-group
                    v-model="formData.treatment.drugs.opt"
                    @change="otherChange($event, 'drugs')"
                    :disabled="modify"
                  >
                      <el-checkbox label="NSAIDs外用" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="NSAIDs口服" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="氨糖" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="中药外用" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="中药内服" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="grid-content-left" v-show="formData.treatment.drugs.other.show">
                  <div class="from-topic">其他药物：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.drugs.other.value"
                    :disabled="modify"
                  ></el-input>
                </div>
                <div class="grid-content-left" style="margin-top:0px;">
                  <div class="from-topic">康复理疗：</div>
                  <el-checkbox-group
                    v-model="formData.treatment.physio.opt"
                    @change="otherChange($event, 'physio')"
                    :disabled="modify"
                  >
                      <el-checkbox label="电疗" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="按摩" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="针灸" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="小针刀" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="艾灸" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="冲击波" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="grid-content-left" v-show="formData.treatment.physio.other.show">
                  <div class="from-topic">其他康复理疗：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.physio.other.value"
                    :disabled="modify"
                  ></el-input>
                </div>

                <div class="grid-content-left" style="margin-top:0px;">
                  <div class="from-topic">关节穿刺：</div>
                  <el-checkbox-group
                    v-model="formData.treatment.puncture.opt"
                    @change="punctureChange"
                    :disabled="modify"
                  >
                      <el-checkbox label="玻璃酸钠" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="激素" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <!-- 玻璃酸钠  -->
                <div class="grid-content-left" v-show="formData.treatment.puncture.ha.show">
                  <div class="from-topic">玻璃酸钠数量：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.puncture.ha.needle"
                    :disabled="modify"
                  ><template slot="append">针</template></el-input>
                  <div class="from-topic" style="margin-left: 10px;">上一次注射时间：</div>
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    :disabled="modify"
                    v-model="formData.treatment.puncture.ha.lastDate"
                    >
                  </el-date-picker>
                </div>
                <!-- 激素  -->
                <div class="grid-content-left" v-show="formData.treatment.puncture.ho.show">
                  <div class="from-topic">激素数量：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.puncture.ho.times"
                    :disabled="modify"
                  ><template slot="append">次</template></el-input>
                </div>
                <!-- 其他  -->
                <div class="grid-content-left" v-show="formData.treatment.puncture.other.show">
                  <div class="from-topic">其他为：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.puncture.other.value"
                    :disabled="modify"
                  ></el-input>
                </div>

                <div class="grid-content-left" style="margin-top:0px;">
                  <div class="from-topic">手术</div>
                  <el-checkbox-group
                    v-model="formData.treatment.operation.opt"
                    @change="operationChange"
                    :disabled="modify"
                  >
                      <el-checkbox label="关节镜下关节清理" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="截骨矫形" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="其他" :border ="true" size="medium"></el-checkbox>
                      <el-checkbox label="无" :border ="true" size="medium"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <!-- 关节清理手术时间  -->
                <div class="grid-content-left" v-show="formData.treatment.operation.addate.show">
                  <div class="from-topic">关节清理手术时间：</div>
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    :disabled="modify"
                    v-model="formData.treatment.operation.addate.value"
                    >
                  </el-date-picker>
                </div>
                <!-- 截骨矫形手术时间  -->
                <div class="grid-content-left" v-show="formData.treatment.operation.ostdate.show">
                  <div class="from-topic">截骨矫形手术时间：</div>
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    :disabled="modify"
                    v-model="formData.treatment.operation.ostdate.value"
                    >
                  </el-date-picker>
                </div>
                <!-- 其他  -->
                <div class="grid-content-left" v-show="formData.treatment.operation.other.show">
                  <div class="from-topic">其他为：</div>
                  <el-input
                    type="text"
                    v-model="formData.treatment.operation.other.value"
                    :disabled="modify"
                  ></el-input>
                  <div class="from-topic" style="margin-left: 10px;">手术时间：</div>
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    :disabled="modify"
                    v-model="formData.treatment.operation.other.date"
                    >
                  </el-date-picker>
                </div>
              </div>
            </div>

            <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left">
                <div class="from-topic">上下楼梯是否需扶手：</div>
                <el-radio-group
                  v-model="formData.staircase" :disabled="modify"
                >
                    <el-radio label="1" :border ="true" >无法上下楼</el-radio>
                    <el-radio label="2" :border ="true" >上楼需要</el-radio>
                    <el-radio label="3" :border ="true" >下楼需要</el-radio>
                    <el-radio label="4" :border ="true" >上下楼均需</el-radio>
                    <el-radio label="5" :border ="true" >不需扶手</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div style=" padding: 0px 20px; width: 100%; overflow: auto;">
              <div class="grid-content-left">
                <div class="from-topic">平地行走最远距离：</div>
                <el-input
                  placeholder="请输入内容"
                  v-model.number="formData.walking_distance"
                  :disabled="modify"
                >
                  <template slot="append">米</template>
                </el-input>
              </div>
            </div>

    </el-row>
<!-- 旧的 -->
    <el-row :gutter="10"
      ref="realForm"
      :model="formData"
      label-width="130px"
      v-show="false"
    >
        <el-col :xs="10" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">受累关节</div></el-col>
        <el-col :xs="14" :sm="20" :md="20" :lg="20" :xl="20" class="el-item-content grid-content mgb0">
          <el-radio-group
            :disabled="modify"
            v-model="formData.damaged_joint.location"
          >
          
            <el-radio :label="'1'">左侧</el-radio>
            <el-radio :label="'2'">右侧</el-radio>
            <el-radio :label="'3'">双侧</el-radio>
          </el-radio-group>
        </el-col>

        <!-- 髋关节疼痛部位 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:0">
          <div  v-show="formData.damaged_joint.location == '1' || formData.damaged_joint.location=='3'" v-if="operation_site==='髋'">
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">左侧疼痛部位</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
              <el-checkbox-group 
              :disabled="modify"
              v-model="formData.damaged_joint.datal.loca"
              >
                <el-checkbox label="前"></el-checkbox>
                <el-checkbox label="侧"></el-checkbox>
                <el-checkbox label="后"></el-checkbox>
              </el-checkbox-group>
            </div>
            </el-col>
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">疼痛时间</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8">
              <el-input :disabled="modify" v-model="formData.damaged_joint.datal.date"></el-input>
            </el-col>
          </div>
        </el-col>

        <el-col style="margin-bottom:0">
          <div v-show="formData.damaged_joint.location == '2' || formData.damaged_joint.location=='3'" v-if="operation_site==='髋'">
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">右侧疼痛部位</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
              <el-checkbox-group :disabled="modify" v-model="formData.damaged_joint.datar.loca" >
                <el-checkbox label="前"></el-checkbox>
                <el-checkbox label="侧"></el-checkbox>
                <el-checkbox label="后"></el-checkbox>
              </el-checkbox-group>
              </div>
            </el-col>
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">疼痛时间</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8">
              <el-input :disabled="modify"  v-model="formData.damaged_joint.datar.date"></el-input>
            </el-col>
          </div>
        </el-col>
        
        <!-- 髋关节诱因 -->
        <div v-show="formData.damaged_joint.location == 1 || formData.damaged_joint.location==3" v-if="operation_site==='髋'">
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">左侧诱因</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" class="el-item-content df grid-content">
          <el-radio-group
            v-model="formData.inducement.whether"
            style="padding-top: 5px"
            :disabled="modify"
          >
            <el-radio :label="'1'" >无</el-radio>
            <el-radio :label="'2'">有</el-radio>
          </el-radio-group>
          <el-input
            type="text"
            class="flex1 mgl10"
            :disabled="modify"
            v-show="formData.inducement.whether == 2"
            v-model="formData.inducement.reason"
          ></el-input>
        </el-col>
        </div>

        <div v-show="formData.damaged_joint.location == 2 || formData.damaged_joint.location==3" v-if="operation_site==='髋'">
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">右侧诱因</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" class="el-item-content df grid-content">
          <el-radio-group
            v-model="formData.inducement.whether1"
            style="padding-top: 5px"
            :disabled="modify"
          >
            <el-radio :label="'1'" >无</el-radio>
            <el-radio :label="'2'">有</el-radio>
          </el-radio-group>
          <el-input
            type="text"
            class="flex1 mgl10"
            :disabled="modify"
            v-show="formData.inducement.whether1 == 2"
            v-model="formData.inducement.reason1"
          ></el-input>
        </el-col>
        </div>
        <!-- 膝关节疼痛时间 -->
        <div v-if="operation_site!='髋'">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div v-show="formData.damaged_joint.location != 2">
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">左侧疼痛时间</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8"><el-input type="text" :disabled="modify"  v-model="formData.damaged_joint.datal.date"></el-input></el-col>
          </div>
          <div v-show="formData.damaged_joint.location != 1">
            <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">右侧疼痛时间</div></el-col>
            <el-col :xs="14" :sm="16" :md="8" :lg="8" :xl="8"><el-input type="text" :disabled="modify" v-model="formData.damaged_joint.datar.date"></el-input></el-col>
          </div>
        </el-col>
        </div>

        <!-- 膝关节诱因 -->
        <div v-if="operation_site!='髋'">
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">是否有诱因</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" class="el-item-content df grid-content">
          <el-radio-group
            v-model="formData.inducement.whether"
            style="padding-top: 5px"
            :disabled="modify"
          >
            <el-radio :label="'1'" >无</el-radio>
            <el-radio :label="'2'">有</el-radio>
          </el-radio-group>
          <el-input
            type="text"
            class="flex1 mgl10"
            :disabled="modify"
            v-show="formData.inducement.whether == 2"
            v-model="formData.inducement.reason"
          ></el-input>
        </el-col>
      </div>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">外伤史</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" class="el-item-content flex1 grid-content">
          <el-radio-group v-model="formData.traumatism.whether" :disabled="modify">
            <el-radio :label="'1'">无</el-radio>
            <el-radio :label="'2'">有</el-radio>
          </el-radio-group>
        </el-col>


      <div class="traumatism white" v-show="formData.traumatism.whether == 2">

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">位置</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <!-- <div>
            <el-radio-group v-show="formData.traumatism.whether == 2" v-model="formData.traumatism.location">
              <el-radio :label="'左膝'">左膝</el-radio>
              <el-radio :label="'右膝'">右膝</el-radio>
            </el-radio-group>
          </div> -->
          <div class="grid-content">
            <el-radio-group v-show="formData.traumatism.whether == 2" v-model="formData.traumatism.location"  :disabled="modify">
              <el-radio :label="'左膝'">左膝</el-radio>
              <el-radio :label="'右膝'">右膝</el-radio>
            </el-radio-group>
          </div>
          <!-- <el-input
            placeholder="请输入内容"
            v-show="formData.traumatism.whether == 2"
            v-model="formData.traumatism.location"
            :disabled="modify"
          >
          </el-input> -->
        </el-col>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">受伤时间</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <el-date-picker
            type="month"
            placeholder="受伤时间"
            value-format="yyyy-MM-dd"
            size="large"
            v-model="formData.traumatism.datal"
            v-show="formData.traumatism.whether == 2"
            :disabled="modify"
          ></el-date-picker>
        </el-col>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">机制</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.mechanism.opt" :disabled="modify">
              <el-radio :label="'1'">摔伤</el-radio>
              <el-radio :label="'2'">起跳落地扭伤</el-radio>
              <el-radio :label="'3'">外力撞伤</el-radio>
              <el-radio :label="'4'">车祸伤</el-radio>
              <el-radio :label="'5'">其他</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-input
          class="mgb10"
          placeholder="请输入内容"
          v-show="formData.traumatism.mechanism.opt == 5"
          v-model="formData.traumatism.mechanism.other"
        >
          <template slot="prepend">其他为</template>
        </el-input>
        </el-col>  

        <el-col :xs="9" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">伤后关节肿胀</div></el-col>
        <el-col :xs="15" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.swollen" :disabled="modify" style="margin-right: 10px !important;">
              <el-radio :label="'1'">否</el-radio>
              <el-radio :label="'2'">是</el-radio>
              <el-radio :label="'3'">不清楚</el-radio>
            </el-radio-group>
          </div>
        </el-col>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">关节疼痛</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.pain" :disabled="modify" >
              <el-radio :label="'1'">否</el-radio>
              <el-radio :label="'2'">轻微</el-radio>
              <el-radio :label="'3'">严重</el-radio>
              <el-radio :label="'4'">不清楚</el-radio>
            </el-radio-group>
          </div>
        </el-col>

        <el-col :xs="12" :sm="10" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">伤时能否负重站立</div></el-col>
        <el-col :xs="12" :sm="14" :md="18" :lg="18" :xl="18">
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.stand" :disabled="modify">
              <el-radio :label="'1'">否</el-radio>
              <el-radio :label="'2'">是</el-radio>
              <el-radio :label="'3'">不清楚</el-radio>
            </el-radio-group>
          </div>
        </el-col>

        <el-col :xs="12" :sm="10" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">伤时能否负重行走</div></el-col>
        <el-col :xs="12" :sm="14" :md="18" :lg="18" :xl="18">
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.walk" :disabled="modify">
              <el-radio :label="'1'">否</el-radio>
              <el-radio :label="'2'">是</el-radio>
              <el-radio :label="'3'">不清楚</el-radio>
            </el-radio-group>
          </div>
        </el-col>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">是否治疗</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.therapy.opt" :disabled="modify">
              <el-radio :label="'1'">否</el-radio>
              <el-radio :label="'2'">是</el-radio>
            </el-radio-group>
          </div>
        </el-col>


        <div v-show="formData.traumatism.therapy.opt == 2">
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">治疗方式</div></el-col>
          <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
            <div class="grid-content">
              <el-checkbox-group
                v-model="formData.traumatism.therapy.way"
                @change="therapyChange"
                :disabled="modify"
              >
                <el-checkbox label="冰敷"></el-checkbox>
                <el-checkbox label="热敷"></el-checkbox>
                <el-checkbox label="西药"></el-checkbox>
                <el-checkbox label="中药"></el-checkbox>
                <el-checkbox label="手术治疗"></el-checkbox>
                <el-checkbox label="其他"></el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </div>

        <div v-show="formData.traumatism.therapy.operation.show">
          <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">手术</div></el-col>
          <el-col :xs="14" :sm="16" :md="20" :lg="20" :xl="20">
            <el-input
              type="text"
              v-model="formData.traumatism.therapy.operation.value"
              :disabled="modify"
            ></el-input>
          </el-col>
        </div>
        <div v-show="formData.traumatism.therapy.other.show">
          <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">其他治疗方式</div></el-col>
          <el-col :xs="14" :sm="16" :md="20" :lg="20" :xl="20" >
            <el-input
              type="text"
              v-model="formData.traumatism.therapy.other.value"
              :disabled="modify"
            ></el-input>
          </el-col>
        </div>

        <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">伤后关节疼痛</div></el-col>
        <el-col :xs="14" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.postinjury_pain" :disabled="modify">
              <el-radio :label="'1'">不痛</el-radio>
              <el-radio :label="'2'">偶痛</el-radio>
              <el-radio :label="'3'">常痛</el-radio>
            </el-radio-group>
          </div>
        </el-col>

        <el-col :xs="10" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">伤后运动能力</div></el-col>
        <el-col :xs="14" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-radio-group v-model="formData.traumatism.athletic" :disabled="modify">
              <el-radio :label="'1'">不影响</el-radio>
              <el-radio :label="'2'">稍下降</el-radio>
              <el-radio :label="'3'">明显下降</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </div>

      <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">治疗情况</div></el-col>
      <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
        <div class="grid-content">
          <el-radio-group v-model="formData.treatment.whether" :disabled="modify">
            <el-radio :label="'1'">无</el-radio>
            <el-radio :label="'2'">有</el-radio>
          </el-radio-group>
        </div>
      </el-col>

      <div v-show="formData.treatment.whether == 2" class="white">
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">药物</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-checkbox-group
              v-model="formData.treatment.drugs.opt"
              @change="otherChange($event, 'drugs')"
              :disabled="modify"
            >
              <el-checkbox label="NSAIDs外用"></el-checkbox>
              <el-checkbox label="NSAIDs口服"></el-checkbox>
              <el-checkbox label="氨糖"></el-checkbox>
              <el-checkbox label="中药外用"></el-checkbox>
              <el-checkbox label="中药内服"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>

        <div v-show="formData.treatment.drugs.other.show">
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">其他为</div></el-col>
          <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
            <el-input
              type="text"
              v-model="formData.treatment.drugs.other.value"
              :disabled="modify"
            ></el-input>
          </el-col>
        </div>

      <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">康复理疗</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-checkbox-group
              v-model="formData.treatment.physio.opt"
              @change="otherChange($event, 'physio')"
              :disabled="modify"
            >
              <el-checkbox label="电疗"></el-checkbox>
              <el-checkbox label="按摩"></el-checkbox>
              <el-checkbox label="针灸"></el-checkbox>
              <el-checkbox label="小针刀"></el-checkbox>
              <el-checkbox label="艾灸"></el-checkbox>
              <el-checkbox label="冲击波"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>

        <div v-show="formData.treatment.physio.other.show">
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">其他为</div></el-col>
          <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
            <el-input
              type="text"
              v-model="formData.treatment.physio.other.value"
              :disabled="modify"
            ></el-input>
          </el-col>
        </div>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">关节穿刺</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-checkbox-group
              v-model="formData.treatment.puncture.opt"
              @change="punctureChange"
              :disabled="modify"
            >
              <el-checkbox label="玻璃酸钠"></el-checkbox>
              <el-checkbox label="激素"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>

        <div v-show="formData.treatment.puncture.ha.show">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="grid-content bg-purple">玻璃酸钠已打</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" >
                <div class="grid-content bg-purple">
                  <el-input v-model="formData.treatment.puncture.ha.needle" :disabled="modify">
                    <template slot="append">针</template>
                  </el-input>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="grid-content bg-purple">上一次注射时间</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                :disabled="modify"
                v-model="formData.treatment.puncture.ha.lastDate"
                >
              </el-date-picker>
            </el-col>
          </el-col>
        </div>
        
        <div v-show="formData.treatment.puncture.ho.show">
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">激素已打</div></el-col>
          <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
            <div class="grid-content bg-purple">
              <el-input v-model="formData.treatment.puncture.ho.times" :disabled="modify">
                <template slot="append">次</template>
              </el-input>
            </div>
          </el-col>
        </div>

        <div v-show="formData.treatment.puncture.other.show">
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">其他为</div></el-col>
          <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
            <el-input
              type="text"
              v-model="formData.treatment.puncture.other.value"
              :disabled="modify"
            ></el-input>
          </el-col>
        </div>

        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">手术</div></el-col>
        <el-col :xs="16" :sm="16" :md="20" :lg="20" :xl="20" >
          <div class="grid-content">
            <el-checkbox-group
              v-model="formData.treatment.operation.opt"
              @change="operationChange"
              :disabled="modify"
            >
              <el-checkbox label="关节镜下关节清理"></el-checkbox>
              <el-checkbox label="截骨矫形"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
              <el-checkbox label="无"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <div v-show="formData.treatment.operation.addate.show">
          <el-col :xs="10" :sm="8" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">关节清理手术时间</div></el-col>
          <el-col :xs="14" :sm="16" :md="19" :lg="19" :xl="19" >
            <!-- <el-input
              type="text"
              v-model="formData.treatment.operation.addate.value"
              :disabled="modify"
            ></el-input> -->
            <el-date-picker
                type="date"
                placeholder="选择日期"
                :disabled="modify"
                v-model="formData.treatment.operation.addate.value"
                >
              </el-date-picker>
          </el-col>
        </div>
        <div v-show="formData.treatment.operation.ostdate.show">
          <el-col :xs="10" :sm="8" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">截骨矫形手术时间</div></el-col>
          <el-col :xs="14" :sm="16" :md="19" :lg="19" :xl="19" >
            <!-- <el-input
              type="text"
              v-model="formData.treatment.operation.ostdate.value"
              :disabled="modify"
            ></el-input> -->
            <el-date-picker
                type="date"
                placeholder="选择日期"
                :disabled="modify"
                v-model="formData.treatment.operation.ostdate.value"
                >
              </el-date-picker>
          </el-col>
        </div>
        <div v-show="formData.treatment.operation.other.show">
          <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">其他为</div></el-col>
          <el-col :xs="16" :sm="16" :md="9" :lg="9" :xl="9">
            <el-input
              type="text"
              v-model="formData.treatment.operation.other.value"
              :disabled="modify"
            ></el-input>
          </el-col>
          <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">手术时间</div></el-col>
          <el-col :xs="16" :sm="16" :md="9" :lg="9" :xl="9">
            <!-- <el-input
              type="text"
              v-model="formData.treatment.operation.other.date"
              :disabled="modify"
            ></el-input> -->
            <el-date-picker
                type="date"
                placeholder="选择日期"
                :disabled="modify"
                v-model="formData.treatment.operation.other.date"
                >
              </el-date-picker>
          </el-col>
        </div>
      </div>

      <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5"><div class="grid-content bg-purple">上下楼梯是否需扶手</div></el-col>
      <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="19" >
        <div class="grid-content">
          <el-radio-group v-model="formData.staircase" :disabled="modify"> 
            <el-radio :label="'1'">无法上下楼</el-radio>
            <el-radio :label="'2'">上楼需要</el-radio>
            <el-radio :label="'3'">下楼需要</el-radio>
            <el-radio :label="'4'">上下楼均需</el-radio>
            <el-radio :label="'5'">不需扶手</el-radio>
          </el-radio-group>
        </div>
      </el-col>

      <el-col :xs="12" :sm="12" :md="8" :lg="5" :xl="5"><div class="grid-content bg-purple">平地行走最远距离</div></el-col>
      <el-col :xs="12" :sm="12" :md="16" :lg="19" :xl="19" >
        <div class="grid-content">
          <el-input
            placeholder="请输入内容"
            v-model.number="formData.walking_distance"
            :disabled="modify"
          >
            <template slot="append">米</template>
          </el-input>
        </div>
      </el-col>
    </el-row>
<!-- 旧的 -->

    <el-button class="gofill" type="primary" @click="goFillin" v-show="showBtn">去填写</el-button>
    <div class="modifyBtn" v-show="!showBtn">
      <el-button type="primary" icon="el-icon-edit" circle @click="toModify" v-show="modify"></el-button>
      <!-- <el-button type="warning" @click="toModify" v-show="modify"
        >修改</el-button
      > -->
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>
      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentHistory",
  components: {},
  data() {
    return {
      operation_site:"",
      titleValue:['受累关节','诱因','外伤史','治疗情况','上下楼情况','平地走最远距离'],
      operation_id: "",
      drugOrder: false,
      showBtn: true,
      backBtn: true,
      nextBtn: true,
      modify: true,
      operateForm: {},
      percentage: 16,
      next: 1,
      dialogVisible: false,
      diawid: "90%",
      formData: {
        damaged_joint: {
          // 受累关节
          location: "",
          datal: {
            loca:[],
            date:"",
            dater:''
          },
          datar: {
            loca:[],
            date:""
          },
        },
        inducement: {
          // 诱因
          whether: "",
          reason: "",
          whether1: "",
          reason1: "",
        },
        traumatism: {
          // 外伤史
          whether: "",
          location: "",
          datal: "",
          mechanism: {
            opt: "",
            other: "",
          },
          swollen: "",
          pain: "",
          stand: "",
          walk: "",
          therapy: {
            opt: "",
            way: [],
            operation:{show: false, value: ""},
            other: { show: false, value: "" },
          },
          postinjury_pain: "",
          athletic: "",
        },
        treatment: {
          // 治疗史
          whether: "",
          drugs: { opt: [], other: { show: false, value: "" } },
          physio: { opt: [], other: { show: false, value: "" } },
          puncture: { opt: [], ha:{ show: false, needle:"", lastDate:"" },ho:{ show: false, times:""}, other: { show: false, value: "" } },
          operation: { opt: [], addate:{ show:false,value:""},ostdate:{ show:false,value:""},other: { show: false, value: "",date:"" } },
        },
        staircase: "",
        walking_distance: "",
        
      },
    };
  },
  mounted(){
    let wid = window.innerWidth
    this.diawid = wid <=480 ? '90%':wid >=1200 ? "60%" : '80%'
  },
  created() {
    this.operation_id = this.$route.query.room;
    this.operation_site = this.$route.query.site;
    this.getPresentHistoryInfo();
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    therapyChange(value) {
      this.formData.traumatism.therapy.other.show = value.includes("其他") ? true : false;
      this.formData.traumatism.therapy.operation.show = value.includes("手术治疗") ? true : false;
    },

    otherChange(value, type) {
      this.formData.treatment[type].other.show = value.includes("其他") ? true : false;
    },
    punctureChange(value){
      this.formData.treatment.puncture.ha.show = value.includes("玻璃酸钠") ? true : false;
      this.formData.treatment.puncture.ho.show = value.includes("激素") ? true : false;
      this.formData.treatment.puncture.other.show = value.includes("其他") ? true : false;
    },
    operationChange(value){
      this.formData.treatment.operation.addate.show = value.includes("关节镜下关节清理") ? true : false;
      this.formData.treatment.operation.ostdate.show = value.includes("截骨矫形") ? true : false;
      this.formData.treatment.operation.other.show = value.includes("其他") ? true : false;

    },


    // 点击修改表单
    toModify() {
      this.modify = !this.modify;
    },
    // 点击确定修改
    sure() {
      this.modify = !this.modify;
      this.postPastHistoryInfo();
    },
    // 点击取消修改
    cancel() {
      this.modify = !this.modify;
    },
    // 点击去填写初始显示
    goFillin() {
      this.dialogVisible = true;
      this.next = 1;
      this.percentage = 16;
    },
    // 点击填写下一步
    nextlist() {
      this.next = this.next < 6 ? this.next + 1 : 6;
      if (this.next < 6) {
        this.percentage += 16;
        this.nextBtn = true;
      } else {
        this.percentage = 100;
        this.nextBtn = false;
      }
    },
    // 上一步
    backlist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.percentage = this.percentage > 20 ? this.percentage - 16 : 20;
      this.nextBtn = this.next > 5 ? false : true;
    },
    // dialog确定
    closedialog() {
      this.postPastHistoryInfo()
      this.dialogVisible = false;
      this.$refs.realForm.allDisabled = true;
      this.showBtn = false;
    },
    // 获取表单请求

    async getPresentHistoryInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Operation/getPreOperationInfo/" + this.operation_id,
          {
            params: { pre_type: "现病史" },
          }
        );
        if (res.status == 0) {
          this.showBtn = true
        }else
        if (res.status == 1) {
          let value = res.data;
            for(let key in value){
              this.formData[key] = value[key] ? value[key] : this.formData[key]
          }
          // this.formData = value
          this.showBtn = false;
          // console.log(res, "现病史请求数据");
        }
      } catch (error) {
        // console.log(error);
      }
    },

    // 更改表单请求
    async postPastHistoryInfo() {
      try {
        const { data: res } = await this.$http.post(
          "/Operation/addPreOperationInfo/" + this.operation_id,
          this.formData
        );
        if (res.status == 1) {
          this.$message.success("修改成功")
          this.getPresentHistoryInfo()
          console.log(res, "现病史写入成功");
        }
      } catch (error) {}
    },
  },
};
</script>

<style>
.PresentHistory {
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  /* position: relative; */
}
.PresentHistory .el-col {
  height: 40px;
  margin-bottom: 10px;
}
.PresentHistory .grid-content-left{
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}
.PresentHistory .from-topic{
  /* width:fit-content */
  min-width:140px;
}
.PresentHistory .el-radio__input.is-disabled+span.el-radio__label{
  color:#000;
}
.PresentHistory .el-col .el-col__label {
  color: #fff !important;
  background-color: #616876 !important;
  margin-bottom: 0 !important;
  border-radius: 4px 0 0 4px !important;
  text-align: center !important;
  padding: 0 5px 0 5px !important;
}
.PresentHistory .white .el-col__label {
  background-color: #f5f7fa !important;
  color: #909399 !important;
  border: 1px solid #dcdfe6 !important;
  border-right: 0 !important;
  border-radius: 4px 0 0 4px !important;
  height: 40px !important;
  box-sizing: border-box !important;
}
.el-item-content {
  border: 1px solid #dcdfe6;
  border-radius: 0 4px 4px 0;
  padding: 0px 0 0 10px;
  height: 36px;
}
.PresentHistory .el-radio {
  margin-bottom: 0;
  margin-right:0;
}
.PresentHistory .el-date-editor.el-input {
  width: 100%;
}
.PresentHistory .el-input {
  width: 100%;
}
.PresentHistory .el-input__inner{
  height: 36px;
}
.PresentHistory .el-checkbox {
  margin: 0;
}
.PresentHistory .el-date-editor {
  width: 100%;
}
.df {
  display: flex;
  flex-direction: row;
}
.flex1 {
  flex: 1;
}
.pl0 {
  padding-left: 0;
}
.mgb10 {
  margin-bottom: 10px;
}
.mgb0{
  margin-bottom: 0;
}
.mgl10{
  /* margin-left: 10px; */
}
.PresentHistory .modifyBtn{
  position: absolute;
  right: 15%;
  top: 60%;
  display: flex;
  flex-direction: column;
}
.modifyBtn .el-button{
  margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
  .PresentHistory .modifyBtn {
  right: 3%;
  top: 75%;
}
@media screen and (max-width: 480px) {
  .PresentHistory .grid-content-left {
    display: contents;
    align-items: baseline;
    margin: 10px 0;
  }
  .PresentHistory .from-topic{
    margin: 10px 0;
  }
}
}
.PresentHistory .gofill{
  position: absolute;
  left: 50%;
  margin-left: -42px;
}
</style>