// 导入模块
const {areaData } = require('element-china-area-data');

// 家族史数据处理
  export function family(value){
    let arr=[]
    for(let i=0;i<value.length;i++){
      let obj={},fam1=value[i]
      obj[i]=fam1.radiovalue,obj[i+1]=fam1.inputvalue
      arr.push(obj)
    }
    return JSON.stringify(arr);
  }
// 专科体查
  export function phy(value){
    let arr=[]
    for(let key in value){
      arr.push(value[key])
    }
   return arr
  }
  export function getDate(){
    let myDate = new Date()
    let y = myDate.getFullYear()
    let month = myDate.getMonth()+1
    let day = myDate.getDate()
    let m = month < 10 ? '0'+month:month
    let d = day < 10 ? '0'+day:day
    let str = y.toString()
    let res = str.slice(-2) + m + d
    return res
  }

  export function toinfomation(values){
    let value = values
    let valuename=["patients_name","id_card","phone","native","gender","marital_status","work_unit", "occupation", "nation","birth_date","stature", "weight","birth_weather", "birth_seasons","home_address"]
    let arr=[]
    for(let i=0;i<valuename.length;i++){
    for(let key in value){
      if(key == valuename[i]){
        arr.push(value[key])
      }
    }
  }
   return arr
  }
  export function debounce(fn, wait) {
    let timeout = null;
    wait = wait || 600;
    return function () {
      let that = this;
      if(timeout !== null)   clearTimeout(timeout);  
      timeout = setTimeout(() => {
        fn.apply(that);
      }, wait);
    }    
}

export function chuli1(values) {
  const provinces = [
    '北京市', '天津市', '上海市', '重庆市', '河北省', '山西省', '辽宁省', '吉林省', '黑龙江省',
    '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省',
    '广东省', '海南省', '四川省', '贵州省', '云南省', '陕西省', '甘肃省', '青海省', '台湾省',
    '内蒙古自治区', '广西壮族自治区', '西藏自治区', '宁夏回族自治区', '新疆维吾尔自治区', '香港特别行政区', '澳门特别行政区'
  ];
  let len = values.length, errprivin = [], rightprivin = [];
  for (let i = 0; i < len; i++) {
    let phoneobj = {value1:'',value2:'',value3:''}
    let obj = values[i];
    phoneobj.value1 = obj.本人联系电话
    phoneobj.value2 = obj.亲属联系电话1
    phoneobj.value3 = obj.亲属联系电话2
    let birth=obj.出生日期
    let location = obj.籍贯; 
    let family = obj.家庭住址; 
    let time = new Date((birth - (25567 + 2)) * 86400 * 1000);
    let Y = time.getFullYear()
    // 效果，当月份或者日期小于10的时候，自动在前面补充0
    let m = String(time.getMonth() + 1).padStart(2, '0');
    let d =  String(time.getDate()).padStart(2, '0');
    obj.出生日期=Y+'-'+m+'-'+d
    obj.联系电话 = phoneobj
    // 处理籍贯
    const matchs = location.match(/(.*?(省|自治区|特别行政区|市))(.*?市)/);
    // 处理住址
    const reg = /(?<province>[^省]+自治区|.*?省|.*?行政区|.*?市)(?<city>[^市]+自治州|.*?地区|.*?行政单位|.*?盟|市辖区|.*?市|.*?县)(?<county>[^县]+县|.*?市|.*?区|.*?镇|.*?园|.*?湖|.*?港|.*?旗|.*?海域|.*?岛|.*?街道)?(?<village>.*)/;
    const familymatchs = family.match(reg);
     console.log(familymatchs,'familyres')
    if (matchs && familymatchs) {
      const province = matchs[1];
      const familyprovince = familymatchs[1];
      if (provinces.includes(province)&&provinces.includes(familyprovince)) {
        const city = matchs[3];
       obj.籍贯 = province+','+city
       obj.家庭住址 = familymatchs[1]+','+familymatchs[2]+','+familymatchs[3]+','+familymatchs[4]
       rightprivin.push(obj)
      } else {
        errprivin.push(obj);
        console.log(`省份名称错误: ${province}:${familyprovince}`);
      }
    } else {
      errprivin.push(obj);
      console.error('无法匹配省市信息');
    }
  }
     return { errprivin, rightprivin };
}


export function chuli(values) {
  const provinces = new Set([
    '北京市', '天津市', '上海市', '重庆市', '河北省', '山西省', '辽宁省', '吉林省', '黑龙江省',
    '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省',
    '广东省', '海南省', '四川省', '贵州省', '云南省', '陕西省', '甘肃省', '青海省', '台湾省',
    '内蒙古自治区', '广西壮族自治区', '西藏自治区', '宁夏回族自治区', '新疆维吾尔自治区', '香港特别行政区', '澳门特别行政区'
  ]);

  const addressReg = /(?<province>[^省]+自治区|.*?省|.*?行政区|.*?市)(?<city>[^市]+自治州|.*?地区|.*?行政单位|.*?盟|市辖区|.*?市|.*?县)(?<county>[^县]+县|.*?市|.*?区|.*?镇|.*?园|.*?湖|.*?港|.*?旗|.*?海域|.*?岛|.*?街道)?(?<village>.*)/;

  function formatDate(dateValue) {
    const time = new Date((dateValue - (25567 + 2)) * 86400 * 1000);
    const year = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, '0');
    const day = String(time.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const errprivin = [];
  const rightprivin = [];

  values.forEach(obj => {
    let localhostprovin = obj.籍贯
    let familyprovin = obj.家庭住址
    let phoneobj = {
      value1: obj.本人联系电话 || '',
      value2: obj.亲属联系电话1 || '',
      value3: obj.亲属联系电话2 || ''
    };
    obj.出生日期 = formatDate(obj.出生日期);
    obj.联系电话 = phoneobj;
    const locationMatch = obj.籍贯.match(/(.*?(省|自治区|特别行政区|市))(.*?市)/);
    const familyMatch = obj.家庭住址.match(addressReg);
    // console.log(familyMatch,'破iu有')
    if (locationMatch && familyMatch) {
      const province = locationMatch[1];
      const familyProvince = familyMatch.groups.province;
      const isProvinceValid = provinces.has(province);
      const isFamilyProvinceValid = provinces.has(familyProvince);  
      if (isProvinceValid && isFamilyProvinceValid) {
        const city = locationMatch[3];
        obj.籍贯 = `${province},${city}`;
        obj.家庭住址 = `${familyMatch.groups.province},${familyMatch.groups.city},${familyMatch.groups.county || ''},${familyMatch.groups.village || ''}`;
        rightprivin.push(obj);
      } else {
        if (!isProvinceValid) {
          obj.籍贯 = {
            value:localhostprovin,
            isred:true
          }
          console.log('Province condition failed.',`省份名称错误: ${province}:${familyProvince}`);
      }
      if (!isFamilyProvinceValid) {
        obj.家庭住址 = {
          value:familyprovin,
          isred:true
        }
          console.log('Family Province condition failed.',`省份名称错误: ${province}:${familyProvince}`);
      }
        errprivin.push(obj);
        // console.log(`省份名称错误: ${province}:${familyProvince}`);
      }
    } else {
      if (!locationMatch) {
        obj.籍贯 = {
          value:localhostprovin,
          isred:true
        }
        console.log('籍贯无法匹配省份信息Location match failed.',locationMatch);
    }
    if (!familyMatch) {
      obj.家庭住址 = {
        value:familyprovin,
        isred:true
      }
        console.log('住址无法匹配省份信息Family match failed.',familyMatch);
    }
      errprivin.push(obj);
      // console.error('无法匹配省市信息');
    }
    // console.log(errprivin)
  });
  console.log(errprivin)
  return { errprivin, rightprivin };
}



  