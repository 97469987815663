<template>
    		<div class="test">
			<div :class="['level-line',sel>= 0 ? 'selborder':'nosel']" id='lastlevel'  @click="btn('0')">
                <el-tooltip class="item" effect="dark" content="0级" placement="top-start">
				<div :class="['fill',sel>= 0 ? 'fillsel':'fillnosel']"></div>
                </el-tooltip>
			</div>
            <!-- {{ dengji }} -->
			<div :class="['level-line',index >= sel ? 'nosel':'selborder']"  v-for="(item,index) in num" :key="index" @click="btn(index+1)">
                <el-tooltip class="item" effect="dark" :content="index+1+'级'" placement="top-start">
				<div :class="['fill',index >= sel ? 'fillnosel':'fillsel']"></div>
                </el-tooltip>
			</div>
			&nbsp;
			{{ dengji }}级
		</div>
</template>

<script>
export default {
    data(){
        return{
            num:5,
		   dengji:0
        }
    },
    props:['sel',"alt"],
    watch:{
        sel(newvalue){
			this.dengji=newvalue
        }
    },
	mounted(){
		console.log(window.innerWidth)
	},
    methods: {
				btn(value){
					if(!this.alt){
					this.dengji = value
                    this.$emit('itemselected',value);
					}
				
				}
			},
}
</script>

<style>
		.test{
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: flex-start;
		}
        .level-line {
			cursor: pointer;
			margin-left: 45px;
			position: relative;
            width:20px; 
            height: 20px; 
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
        }
		.selborder{
			border: 2px solid #438efd;
		}
		.selborder ::after{
			background-color: #438EFD;
		}
		.nosel{
			border: 2px solid #edeae2;
		}
		.nosel ::after{
			background-color: #edeae2;
		}
		.level-line ::after{
			position: absolute;
			content: '';
			right:19px;
			top: calc(50% - 3px) ;
			width: 43px;
			height: 0.3125rem;
		}
		.fill{
			width:12px; 
			height: 12px; 
			border-radius: 50%; 
		}
		.fillsel{
			background-color: #438efd;
		}
		.fillnosel{
			background-color: #edeae2;
		}
		#lastlevel ::after{
			width: 0px;
		}
		#lastlevel{
		margin-left: 0px;
	}
		@media screen and (max-width:480px) {
		.level-line ::after{
			
			right:18px;
			width: 25px;
			height: 0.3125rem;
		}
    .level-line{
		margin-left: 25px;
			}

		}
		@media screen and (max-width:1536px) {
			.level-line {
			margin-left: 25px;
        }
		.level-line ::after{
			width: 24px;
		}
		}
</style>